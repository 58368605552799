.flexColumn {
    @include flexColumn;
}

.flexRow {
    @include flexRow;
}

.flexRowReverse {
    @include flexRowReverse;
}

.flexJusCenter {
    @include flexJusCenter;
}

.flexColumnJusCenter {
    @include flexColumnJusCenter;
}

.flexColumnJusBetween {
    @include flexColumnJusBetween;
}

.flexColumnAlignCenter {
    @include flexColumnAlignCenter;
}

.flexCenter {
    @include flexCenter;
}

.flexColumnCenter {
    @include flexColumnCenter;
}

.flexBetween {
    @include flexBetween;
}

.flexBetweenCenter {
    @include flexBetweenCenter;
}

.flexAlignCenter {
    @include flexAlignCenter;
}

.flexAlignStart {
    @include flexAlignStart;
}

.textEllipsis {
    @include textEllipsis;
}

.flexRowCenterBetween {
    @include flexRowCenterBetween;
}

.primaryColor {
    color: #cb1b5b !important;
}

.bgPrimary {
    background: #cb1b5b !important;
}