.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background: #fff;

    .logout-icon {
        width: 25px;
        height: 25px;
        background-color: #CB1B5B;
        border-radius: 7px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logout-icon>img {
        width: 40%;
    }

    .header-logout {
        cursor: pointer;
        color: #CB1B5B;
        font-size: 13px;
        display: flex;
        align-items: center;
    }

    .left-container {
        display: flex;
        align-items: center;

        >img {
            height: 80%;
        }
    }

    .right-container {
        height: 100%;
        margin-top: -2%;
    }
}

.time,
.date,
.curdate {
    font-size: 15px;
}