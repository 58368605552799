@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
}

@mixin flexRowReverse {
    display: flex;
    flex-direction: row-reverse;
}


@mixin flexJusCenter {
    display: flex;
    justify-content: center;
}

@mixin flexColumnJusCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin flexColumnJusBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin flexColumnAlignCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flexCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin flexColumnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin flexBetween {
    display: flex;
    justify-content: space-between;
}

@mixin flexBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flexAlignCenter {
    display: flex;
    align-items: center;
}

@mixin flexAlignStart {
    display: flex;
    align-items: start;
}

@mixin textEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin flexRowCenterBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}