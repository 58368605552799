.faq-title {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    margin-left: 1.25%;
    font-weight: 700;
    font-size: 15px;

    >span:nth-child(4) {
        padding-left: 0.3rem;
    }

    >span:nth-child(3) {
        padding-left: 0.3rem;
    }

    >span:nth-child(2) {
        padding-left: 0.3rem;
        font-weight: bold;
    }

    >span:nth-child(5) {
        padding-left: 0.3rem;
    }
}

.faq-addnew {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    margin-left: 2%;
    margin-bottom: 1%;
}

.newPharmacyIdBtn {
    width: 280px !important;
}

.addNewfaq {
    width: 230px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #e8f1ff;
    cursor: pointer;

    >div:nth-child(1) {
        width: 40px;
        height: 90%;
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: 30px;
        color: #65a0ff;
        border: 1px solid #e8f1ff;
        background: #e8f1ff;
        // border: 1px solid red;
    }

    >div:nth-child(2) {
        width: 80%;
        height: 90%;

        // border: 1px solid red;
        button {
            width: 100%;
            height: 100%;
            border: 1px solid #e8f1ff;
            background: #e8f1ff;
            color: #3b86ff;
            font-size: 14px;
        }
    }
}

.addNewFaqSection {
    width: 320px;
    height: 50px;
    margin-left: 2%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #65a0ff;
    cursor: pointer;

    >div:nth-child(1) {
        width: 290px;
        height: 90%;

        button {
            width: 100%;
            height: 100%;
            border: 1px solid transparent;
            background: #fff;
            color: #65a0ff;
            font-size: 14px;
        }
    }

    >div:nth-child(2) {
        width: 30px;
        height: 90%;
        display: flex;
        align-items: center;
        font-size: 25px;
        color: #65a0ff;
        background: #fff;
        font-size: 15px;
        padding-right: 1rem;
        margin-right: 0.7rem;
    }
}

.faq-tab {
    width: 100%;
    height: auto;
    overflow: scroll;
    display: flex;
    font-size: 15px;
    background: #fff;
    overflow: hidden;

    >div {

        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 1px;
        cursor: pointer;
    }

    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(4) {
        margin-left: 7%;
        color: #65626C
    }
}

.faq-tab .book {
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
    font-weight: 500;
}

.other {
    margin-left: 4% !important;
    margin-right: 4% !important;
}

.rose-line {
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
    font-weight: 500;
}

.faq-section-form {
    width: 97%;
    margin: auto;
}

.new-faq-section-form {
    margin-top: 3%;
}

.new-faq-section-form-txt {
    font-size: 13px;
}

.new-faq-section-form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25%;

    >button {
        width: 166px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #54a464;
        background: #54a464;
        color: white;
    }
}

.faqContainer,
.appDownloadLinkContainer {
    background: #fff;
}

.questionWrapper {
    background: #f8f9fa;
    border-radius: 5px;
}

.questionContent {
    width: 100% !important;
    max-width: 1250px;
}

.answerWrapper {
    border-radius: 5px;
    height: 300px;
    background: #f8f9fa;
}

.h-150 {
    height: 150px !important;
}

.answerWrapper:focus-visible,
.questionWrapper:focus-visible {
    border: 1px solid #ced4da !important;
}

.iconWrapper {
    max-width: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.editIcon,
.deleteIcon {
    cursor: pointer;
}

.createFaqBtn {
    width: 165px;
    height: 50px;
}