.redirectorder {
  width: 97%;
}

.redirectorder-one {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2%;
  > div:nth-child(1) {
    width: 20%;
    height: 100%;
    font-weight: 700;
    color: #181423;
    font-size: 15px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  > div:nth-child(2) {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    > div {
      color: #cb1b5b;
      font-weight: 700;
      font-size: 13px;
    }
  }
}

.redirectorder-two {
  width: 100%;
}

.redirectorder-two-container {
  margin-top: 2%;
  display: flex;
  width: 100%;
  height: 15vh;
  margin-left: 2%;
  border-bottom: 1px solid rgb(233, 227, 227);
  > div:nth-child(1) {
    width: 10%;
    height: 100%;
    > img {
      width: 32px;
      height: 32px;
    }
  }
  > div:nth-child(2) {
    width: 30%;
    height: 100%;
    > div:nth-child(1) {
      font-size: 15px;
      font-weight: 700;
      color: #181423;
      height: 30%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
    }
    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: #4b4853;
      height: 30%;
      width: 60%;
    }
    > div:nth-child(3) {
      font-size: 13px;
      font-weight: 500;
      color: #cb1b5b;
      height: 30%;
      width: 100%;
    }
  }
  > div:nth-child(3) {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    color: #98969d;
    font-size: 13px;
    > span {
      text-decoration: line-through;
    }
  }
  > div:nth-child(4) {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    > div {
      background-color: #25c678;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      width: 38px;
      height: 14px;
      font-size: 7px;
      color: #f8f8f8;
    }
  }
  > div:nth-child(5) {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: left;
    color: #181423;
    font-size: 13px;
    font-weight: 700;
  }
  > div:nth-child(6) {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: right;
  }
}

.redirect-button {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 3%;
  > button {
    width: 166px;
    height: 50px;
    background: #3b86ff;
    border: 1px solid #3b86ff;
    color: #fff;
    font-size: 15px;
    border-radius: 4px;
  }
}
