.home-healthcare-hhsp{
    table{
        width: 100%;
    }
    table thead tr td:nth-child(1){
        width: 60px;
    }
    table thead tr td:nth-child(2){
        width: 180px;
    }
    table thead tr td:nth-child(3){
        width: 120px;
    }
    table thead tr td:nth-child(4){
        width: 150px;
    }
    table thead tr td:nth-child(5){
        width: 150px;
    }
    table thead tr td:nth-child(6){
        width: 300px;
    }
}

.sortby-hhsp{
    top: 55%;
}