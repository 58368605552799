.BreadcrumbSection {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
}

.doctorEditFormWrapper{
    .BreadcrumbSection{
        width: 70%;
        flex-wrap: wrap;
    }
}