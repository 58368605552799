.partneredDoctorForm {
    .partneredDoctorAddBtn {
        width: 83px;
        height: 46px !important;
        margin-top: 1px;
        background: #3B86FF;
        border: none;
        margin-left: 18px;
        color: white;
        border-radius: 5px;
    }
}

.doctorProfileImage {
    width: 100px;
    max-height: 100px;
    object-fit: contain;
    margin: 5px 0;
}

.partneredDoc-table {
    .partneredDocTimeSlot {
        width: 90px;
        height: 35px;
        border: none;
        background: #14a914;
        color: #fff;
        border-radius: 5px;
    }

    .partneredDocUpcomingSlot {
        width: 110px;
        background: rgb(238, 180, 73);
        color: #000000;
    }

    .partneredDocPastSlot {
        background: rgb(29, 149, 197);
    }
}

.z-2 {
    z-index: 2;
}

.clinicNameCard {
    width: 490px;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.removeClinicBtn {
    color: red;
    cursor: pointer;
}

.clinicDetailsCard {
    padding-left: 25px;
}

.partneredDoctorNextBtn {
    width: 226px !important;
    margin-top: 0 !important;
}

.filter-container-partnered-doctor {
    width: 100%;
    max-width: 310px;
    // width: 229px;
    // height: 385px;
    position: fixed;
    left: 40%;
    top: 35%;
    padding: 24px;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 2s;
}

.filter-content-partnered-doctor {
    padding: 26px 24px 0;
}

.filter-content-card-partnered-doctor {
    padding: 0 0 32px;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    justify-content: space-between;
}

.filter-doctor-fee-partnered-doctor {
    width: 100%;
    max-width: 316px;
    // height: 450px;
    position: fixed;
    padding: 24px;
    left: 58%;
    top: 35%;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    // overflow: scroll;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 0.2s;
    animation: animationCard 0.2s linear;
}

.filter-partnered-doctors-container {
    padding: 24px 0;
    height: 450px !important;
    overflow: scroll !important;
}

.doctorsFilterModal {
    margin: 24px 0 0;
    font-size: 16px !important;
}

.doctorsFilterModalHeader {
    font-size: 20px !important;
}

.partneredDoctorFooterBtn button {
    width: 100% !important;
    max-width: 121px !important;
    border-radius: 8px !important;
    font-size: 20px !important;
    margin: 16px 0 0;
    padding: 0;
}

.doctorFilterModalHeader {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 20px;
    padding-bottom: 18px;
}

.filterModalSelectOption {
    font-size: 16px;
}

.rootFilterModal {
    cursor: pointer;
    padding: 0 0 32px;
}

.doctorFilterModalHr {
    margin: 18px auto 24px !important;
}

.doctorsFilterModalBox {
    font-size: 16px;
    color: #4D4D4D;
    padding-top: 24px;
}

.sortby-doctors-records {
    width: 100%;
    max-width: 166px !important;
    padding: 24px !important;
    position: absolute;
    right: 15%;
    top: 48%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px rgba(71, 71, 71, 0.1607843137);
    border-radius: 5px;
}

.sortByBtn {
    width: 100%;
    max-width: 118px;
    padding: 8px;
    color: #fff;
    border: none;
    margin-top: 24px;
    border-radius: 8px;
    background-color: #CB1B5B;
}

.sort-by-checkbox-wrapper input[type=checkbox] {
    border: 1px solid gray !important;
}

.sort-by-checkbox-wrapper input:checked {
    border: 1px solid #CB1B5B !important;
}


.range-slider .range-slider__thumb {
    background-color: #fff;
    border: 1px solid #CB1B5B !important;
}

.range-slider .range-slider__range {
    background-color: #CB1B5B;
}

.doctor-fees-range{
    margin-bottom: 85px !important;
}