.doctorregistetr {
    overflow-x: scroll;
}

.pink-line {
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
    font-weight: 500;
}

.primaryColor {
    color: #CB1B5B !important;
}

.careers-tab {
    height: 60px;
    // font-size: 0.7rem;
    border-bottom: 1px solid #F2F2F2;

}

.top-container {
    background-color: #fff;
}

.other {
    margin-left: 4% !important;
    margin-right: 4% !important;
}

.bread-crumb {

    padding: 0.15rem;
}

.bread-crumb1 {

    padding: 0.15rem;
}

.doctor-demo-table {

    table {
        width: 1458px;
    }

    table thead tr td {
        padding: 20px 10px;
        background-color: #f8f8f8;
        border: 1px solid #d4d4d4;
        font-size: 13px;
        font-weight: bold;
    }

    table thead tr td:nth-child(1) {
        width: 50px;
    }

    table thead tr td:nth-child(2) {
        width: 120px;
    }

    table thead tr td:nth-child(3) {
        width: 150px;
    }

    table thead tr td:nth-child(4) {
        width: 150px;
    }

    table thead tr td:nth-child(5) {
        width: 150px;
    }

    table thead tr td:nth-child(6) {
        width: 250px;
    }

    table thead tr td:nth-child(7) {
        width: 200px;
    }

    table thead tr td:nth-child(8) {
        width: 114px;
    }

    table thead tr td:nth-child(9) {
        width: 114px;
    }

    table thead tr td:nth-child(10) {
        width: 114px;
    }

    .t-head td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 0.5rem;
        padding-right: 1.5rem;
        background-color: #f8f8f8;
        text-align: left;
    }

    table tbody tr td {
        font-size: 13px;
    }
}

.doctorregistetr {

    table {
        width: 3002px;
    }

    .t-head td {
        background-color: #f8f8f8;
    }

    table thead tr td {
        background-color: #f8f8f8;
        text-align: left;
        font-size: 13px;
    }

    table thead tr td:nth-child(1) {
        min-width: 100px;
    }

    table thead tr td:nth-child(2) {
        min-width: 142px;
    }

    table thead tr td:nth-child(3) {
        min-width: 140px;
    }

    table thead tr td:nth-child(4) {
        min-width: 138px;
    }

    table thead tr td:nth-child(5) {
        min-width: 180px;
    }

    table thead tr td:nth-child(6) {
        min-width: 200px;
    }

    table thead tr td:nth-child(7) {
        min-width: 200px;
    }

    table thead tr td:nth-child(8) {
        min-width: 170px;
    }

    table thead tr td:nth-child(9) {
        min-width: 150px;
    }

    table thead tr td:nth-child(10) {
        min-width: 154px;
    }

    table thead tr td:nth-child(11) {
        min-width: 173px;
    }

    table thead tr td:nth-child(12) {
        min-width: 135px;
    }

    table thead tr td:nth-child(13) {
        min-width: 147px;
    }


    table thead tr td:nth-child(14) {
        min-width: 150px;
    }

    table thead tr td:nth-child(15) {
        min-width: 150px;
    }

    table thead tr td:nth-child(16) {
        min-width: 120px;
    }

    table thead tr td:nth-child(17) {
        min-width: 120px;
    }

    table thead tr td:nth-child(18) {
        min-width: 120px;
    }

    table thead tr td:nth-child(19) {
        min-width: 100px;
    }

    table thead tr td:nth-child(20) {
        min-width: 150px;
    }

    table thead tr td:nth-child(21) {
        min-width: 150px;
    }

    table thead tr td:nth-child(22) {
        min-width: 150px;
    }

    table thead tr td:nth-child(23) {
        min-width: 150px;
    }

    table thead tr td:nth-child(24) {
        min-width: 200px;
    }

    table thead tr td:nth-child(25) {
        min-width: 170px;
    }

    table thead tr td:nth-child(26) {
        min-width: 150px;
    }

    table thead tr td:nth-child(27) {
        min-width: 150px;
    }

    table thead tr td:nth-child(28) {
        min-width: 200px;
    }

    table thead tr td:nth-child(29) {
        min-width: 200px;
    }

    table thead tr td:nth-child(30) {
        min-width: 200px;
    }

    table thead tr td:nth-child(31) {
        min-width: 155px;
    }

    table thead tr td:nth-child(32) {
        min-width: 175px;
    }

    table thead tr td:nth-child(33) {
        min-width: 200px;
    }

    table thead tr td:nth-child(34) {
        min-width: 150px;
    }

    table thead tr td:nth-child(35) {
        min-width: 165px;
    }

    table thead tr td:nth-child(36) {
        min-width: 150px;
    }

    table thead tr td:nth-child(37) {
        min-width: 220px;
    }

    table thead tr td:nth-child(38) {
        min-width: 200px;
    }

    table thead tr td:nth-child(39) {
        min-width: 200px;
    }

    table thead tr td:nth-child(40) {
        min-width: 200px;
    }

    table thead tr td:nth-child(41) {
        min-width: 200px;
    }

    table thead tr td:nth-child(42) {
        min-width: 200px;
    }

    table thead tr td:nth-child(43) {
        min-width: 200px;
    }

    table thead tr td:nth-child(44) {
        min-width: 200px;
    }

    table thead tr td:nth-child(45) {
        min-width: 200px;
    }

    table thead tr td:nth-child(46) {
        min-width: 250px;
    }

    table thead tr td:nth-child(47) {
        min-width: 200px;
    }

    table thead tr td:nth-child(48) {
        min-width: 150px;
    }

    table thead tr td:nth-child(49) {
        min-width: 150px;
    }

    table thead tr td:nth-child(50) {
        min-width: 150px;
    }

    table thead tr td:nth-child(51) {
        min-width: 150px;
    }

    table thead tr td:nth-child(52) {
        min-width: 150px;
    }

    table thead tr td:nth-child(53) {
        min-width: 360px;
    }

    table thead tr th {
        padding: 10px 5px;
        padding-left: 0.5%;
        border: 1px solid #d4d4d4;
    }

    table tbody tr td {
        padding: 25px 10px;
        padding-left: 0.5%;
        font-size: 13px;
    }

}

.Pharmacy-Reg-Table {
    table {
        width: 3514px;
    }

    table thead tr td {
        padding: 10px 5px;
        padding-left: 0.5%;
        background-color: #f8f8f8;
        border: 1px solid #d4d4d4;
        font-size: 13px;
    }

    table tbody tr td {
        padding: 15px 10px;
        padding-left: 0.5%;
        font-size: 13px;
    }

    table thead tr td:nth-child(1) {
        min-width: 80px;
    }

    table thead tr td:nth-child(2) {
        min-width: 170px;
    }

    table thead tr td:nth-child(3) {
        min-width: 175px;
    }

    table thead tr td:nth-child(4) {
        min-width: 160px;
    }

    table thead tr td:nth-child(5) {
        min-width: 160px;
    }

    table thead tr td:nth-child(6) {
        min-width: 160px;
    }

    table thead tr td:nth-child(7) {
        min-width: 160px;
    }

    table thead tr td:nth-child(8) {
        min-width: 120px;
    }

    table thead tr td:nth-child(9) {
        min-width: 140px;
    }

    table thead tr td:nth-child(10) {
        min-width: 210px;
    }

    table thead tr td:nth-child(11) {
        min-width: 120px;
    }

    table thead tr td:nth-child(12) {
        min-width: 150px;
    }

    table thead tr td:nth-child(13) {
        min-width: 130px;
    }

    table thead tr td:nth-child(14) {
        min-width: 150px;
    }

    table thead tr td:nth-child(15) {
        min-width: 150px;
    }

    table thead tr td:nth-child(16) {
        min-width: 200px;
    }

    table thead tr td:nth-child(17) {
        min-width: 150px;
    }

    table thead tr td:nth-child(18) {
        min-width: 150px;
    }

    table thead tr td:nth-child(19) {
        min-width: 150px;
    }

    table thead tr td:nth-child(20) {
        min-width: 155px;
    }

    table thead tr td:nth-child(21) {
        min-width: 125px;
    }

    table thead tr td:nth-child(22) {
        min-width: 202px;
    }

    table thead tr td:nth-child(23) {
        min-width: 245px;
    }

    table thead tr td:nth-child(24) {
        min-width: 210px;
    }

    table thead tr td:nth-child(25) {
        min-width: 230px;
    }

    table thead tr td:nth-child(26) {
        min-width: 260px;
    }

    table thead tr td:nth-child(27) {
        min-width: 170px;
    }

    table thead tr td:nth-child(28) {
        min-width: 210px;
    }

    table thead tr td:nth-child(29) {
        min-width: 150px;
    }

    table thead tr td:nth-child(30) {
        min-width: 180px;
    }

    table thead tr td:nth-child(31) {
        min-width: 150px;
    }

    table thead tr td:nth-child(32) {
        min-width: 150px !important;
    }

    table thead tr td:nth-child(33) {
        min-width: 140px !important;
    }

    table thead tr td:nth-child(34) {
        min-width: 150px !important;
    }

    table thead tr td:nth-child(35) {
        min-width: 160px;
    }

    table thead tr td:nth-child(36) {
        min-width: 150px;
    }

    table thead tr td:nth-child(37) {
        min-width: 150px;
    }

    table thead tr td:nth-child(38) {
        min-width: 250px;
    }

    table thead tr td:nth-child(39) {
        min-width: 150px;
    }

    table thead tr td:nth-child(40) {
        min-width: 200px;
    }

    table thead tr td:nth-child(41) {
        min-width: 350px;
    }
}

.healthcare-table {
    table {
        width: 2161px;
    }

    table thead tr td {
        padding: 10px 5px;
        padding-left: 0.5%;
        font-size: 13px;
        font-weight: bold;
        background-color: #f8f8f8;
    }

    table tbody tr td {
        padding: 15px 10px;
        padding-left: 0.5%;
        font-size: 13px;
    }

    table thead tr td:nth-child(1) {
        min-width: 60px;
    }

    table thead tr td:nth-child(2) {
        min-width: 120px;
    }

    table thead tr td:nth-child(3) {
        min-width: 120px;
    }

    table thead tr td:nth-child(4) {
        min-width: 150px;
    }

    table thead tr td:nth-child(5) {
        min-width: 120px;
    }

    table thead tr td:nth-child(6) {
        min-width: 150px;
    }

    table thead tr td:nth-child(7) {
        min-width: 150px;
    }

    table thead tr td:nth-child(8) {
        min-width: 120px;
    }

    table thead tr td:nth-child(9) {
        min-width: 140px;
    }

    table thead tr td:nth-child(10) {
        min-width: 140px;
    }

    table thead tr td:nth-child(11) {
        min-width: 140px;
    }

    table thead tr td:nth-child(12) {
        min-width: 140px;
    }

    table thead tr td:nth-child(13) {
        min-width: 140px;
    }

    table thead tr td:nth-child(14) {
        min-width: 180px;
    }

    table thead tr td:nth-child(15) {
        min-width: 180px;
    }

    table thead tr td:nth-child(16) {
        min-width: 100px;
    }

    table thead tr td:nth-child(17) {
        min-width: 180px;
    }

    table thead tr td:nth-child(18) {
        min-width: 200px;
    }

    table thead tr td:nth-child(19) {
        min-width: 200px;
    }

    table thead tr td:nth-child(20) {
        min-width: 300px;
    }

    table thead tr td:nth-child(21) {
        min-width: 150px;
    }

    table thead tr td:nth-child(22) {
        min-width: 132px;
    }

    table thead tr td:nth-child(23) {
        min-width: 200px;
    }

    table thead tr td:nth-child(24) {
        min-width: 200px;
    }

    table thead tr td:nth-child(25) {
        min-width: 200px;
    }

    table thead tr td:nth-child(26) {
        min-width: 150px;
    }

    table thead tr td:nth-child(27) {
        min-width: 150px;
    }

    table thead tr td:nth-child(28) {
        min-width: 150px;
    }

    table thead tr td:nth-child(29) {
        min-width: 200px;
    }

    table thead tr td:nth-child(30) {
        min-width: 200px;
    }

    table thead tr td:nth-child(31) {
        min-width: 350px;
    }
}

.other-Reg-Table {
    margin-top: 0.5%;

    table {
        width: 1500px;
    }

    table thead tr td {
        padding: 10px 5px;
        padding-left: 1%;
        background-color: #f8f8f8;
        border: 1px solid #d4d4d4;
        font-size: 13px;
    }

    table thead tr td:nth-child(1) {
        width: 60px;
    }

    table thead tr td:nth-child(2) {
        width: 120px;
    }

    table thead tr td:nth-child(3) {
        width: 150px;
    }

    table thead tr td:nth-child(4) {
        width: 150px;
    }

    table thead tr td:nth-child(5) {
        width: 120px;
    }

    table thead tr td:nth-child(6) {
        width: 120px;
    }

    table thead tr td:nth-child(7) {
        width: 150px;
    }

    table thead tr td:nth-child(8) {
        width: 200px;
    }

    table thead tr td:nth-child(9) {
        width: 128px;
    }

    table thead tr td:nth-child(10) {
        width: 128px;
    }

    table thead tr td:nth-child(11) {
        width: 128px;
    }

    table tbody tr td {
        padding: 30px 10px;
        padding-left: 1%;
        font-size: 13px;
    }
}

.pagination {
    margin-top: 5%;
}



.search-bar-icon {
    position: relative;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.form-control {
    height: 40px;
}

.form-control::placeholder {
    margin-left: 0;
    padding-left: 0;
    color: #000000; 
}
.form-control.w-75::placeholder {
    color: #000000;
}


.sortandfilter-new {
    width: 30%;
    height: 40px;
    display: flex;
    margin-left: 27%;
}

.sortByPosition {
    width: 50%;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: center;
    cursor: pointer;

    >div {
        height: 100%;
        width: 70%;
        border-radius: 5px;
        display: flex;
        background: #E8F1FF 0% 0% no-repeat padding-box;

        >div:nth-child(1) {
            width: 70%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >select {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                background-color: transparent;
                color: #3B86FF;
                font-size: 13px;
                -webkit-appearance: none;
                padding-left: 25%;

                option {
                    div {
                        padding: 20px;
                        color: red;
                    }
                }
            }

        }

        >div:nth-child(2) {
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                width: 50%;
                height: 50%;
                margin-right: 10%;
            }
        }

        >div {
            >div {
                color: #3B86FF;
                font-size: 13px;
            }
        }
    }
}

.sortByDepartment {
    width: 50%;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: center;
    cursor: pointer;

    >div {
        height: 100%;
        width: 70%;
        border-radius: 5px;
        display: flex;
        border: 1px solid #3B86FF;

        >div:nth-child(1) {
            width: 70%;
            height: 100%;

            >div {
                width: 100%;
                height: 100%;
                border: 1px solid transparent;
                background-color: transparent;
                color: #3B86FF;
                font-size: 13px;
                -webkit-appearance: none;
                padding-left: 25%;
                display: flex;
                justify-content: left;
                align-items: center;
            }
        }

        >div:nth-child(2) {
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                width: 60%;
                height: 60%;
                margin-right: 10%;
            }
        }
    }
}


* {
    font-family: 'Mulish';
}

//Actions
.contact {
    color: #0066C0;
    font-size: 13px;
    cursor: pointer;
}

.contact-highlight {
    font-weight: bold;
}

.contact-logo {
    padding-left: 2%;
    cursor: pointer;
}

.onboard {
    color: #71BA80;
    font-size: 13px;
    padding-left: 5%;
    cursor: pointer;
}

.onboard-highlight {
    font-weight: bold;
}

.onboard-logo {
    padding-left: 2%;
    cursor: pointer;
}

.notcontact {
    color: #CCCC0C;
    font-size: 13px;
    padding-left: 5%;
    cursor: pointer;
}

.notcontact-highlight {
    font-weight: bold;
}

.notcontacted-logo {
    padding-left: 2%;
    cursor: pointer;
}

.newdelete {
    color: #FF5248;
    font-size: 13px;
    padding-left: 5%;
    cursor: pointer;
}

.newdelete-logo {
    padding-left: 2%;
    cursor: pointer;
}




//filter portion
.filter-container {
    width: 229px;
    height: 385px;
    position: fixed;
    left: 40%;
    top: 25%;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 2s;
}

.filter-header {
    width: 85%;
    height: 15%;
    color: var(--unnamed-color-181423);
    font-weight: bold;
    color: #181423;
    font-size: 15px;
    margin: auto;
    display: flex;
    justify-content: left;
    align-items: center;
}

.filter-header-hr {
    width: 85%;
    margin: auto;
    color: gray;
    padding-top: 18px;
    border-bottom: 1px solid rgb(233, 228, 228);
}

.filter-content {
    width: 85%;
    height: 15%;
    margin: auto;
    display: flex;

    >div:nth-child(1) {
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        color: #aaa;
        cursor: pointer;
    }

    >div:nth-child(2) {
        width: 20%;
        height: 100%;
        cursor: pointer;
    }
}

.filter-content-doctor {
    width: 85%;
    height: 70%;
    margin: auto;
}

.filter-content-card-doctor {
    width: 100%;
    height: 16%;
    display: flex;

    >div:nth-child(1) {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        color: #65626C;
        font-size: 13px;
        cursor: pointer;
    }

    >div:nth-child(2) {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.rightarrow {
    color: #65626c;
    opacity: 0.8;
}

.filter-foot-btn {
    width: 85%;
    left: 8%;
    height: 15%;
    position: absolute;
    bottom: 0;
    display: flex;

    >div:nth-child(1) {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >button {
            width: 83px;
            height: 34px;
            border: 1px solid #CB1B5B;
            background: #fff;
            color: #CB1B5B;
            border-radius: 2px;
            font-size: 13px;
        }
    }

    >div:nth-child(2) {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >button {
            width: 83px;
            height: 34px;
            border: 1px solid #fff;
            background: #CB1B5B;
            color: #fff;
            border-radius: 2px;
            font-size: 13px;
        }
    }
}

// Doctor Filter Fee
.filter-doctor-fee {
    width: 229px;
    position: fixed;
    left: 58%;
    top: 25%;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 0.2s;
    animation: animationCard 0.2s linear;
}

@keyframes animationCard {
    0% {
        left: 55%;
    }

    50% {
        left: 56%;
    }

    100% {
        left: 58%;
    }
}

.filter-doctor-fee-header {
    width: 85%;
    margin: auto;
    height: 8vh;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
}

.filter-doctor-fee-hr {
    width: 85%;
    margin: auto;
    color: gray;
    border-bottom: 1px solid rgb(233, 228, 228);
}

.filter-doctor-fee-container {
    width: 85%;
    margin: auto;
    margin-bottom: 5%;
}

.filter-doctor-fee-box {
    width: 100%;
    height: 6vh;
    display: flex;

    >div:nth-child(1) {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 13px;
        color: #65626C;
    }

    >div:nth-child(2) {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >input {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.filter-doctor-search {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 100%;
        height: 60%;
        display: flex;
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border-radius: 6px;

        >div:nth-child(1) {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F8F8F8 0% 0% no-repeat padding-box;
        }

        >div:nth-child(2) {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F8F8F8 0% 0% no-repeat padding-box;

            >input {
                width: 95%;
                height: 95%;
                border: 1px solid transparent;
                font-size: 13px;
                background: #F8F8F8 0% 0% no-repeat padding-box;
            }
        }
    }
}

.filter-search::placeholder {
    color: #65626C;
    font-size: 13px;
}


//Sort By portion

.sortby-Common {
    width: 142px;
    height: 178px;
    position: absolute;
    right: 15%;
    top: 48%;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;

    >div {
        width: 80%;
        margin: auto;
        height: 25%;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 13px;
        color: #65626C;
        cursor: pointer;
    }
}


// Custom Checkbox

.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 0.5em;
    border: 1px solid #CB1B5B;
    outline: none;
    cursor: pointer;
}

.checkbox-wrapper input .checked {
    background-color: #CB1B5B;
    position: relative;
}

.checkbox-wrapper input.checked::before {
    content: "2714";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
}

.checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:disabled+span {
    color: #c0c0c0;
}

.checkbox-wrapper input:checked {
    background-color: #CB1B5B;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.checkbox-wrapper input:checked::before {
    content: "";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
}

.instadoc-table {
    table thead tr td {
        min-width: 200px;
    }

    table thead tr td:nth-child(24) {
        min-width: 350px;
    }
}

.healthcareEditFormWrapper,
.instaDocEditFormWrapper,
.healthcareEditFormWrapper,
.doctorEditFormWrapper {
    overflow: scroll;
    height: 90vh;
}

.partneredDoctorLanuages,
.healthcareEditFormWrapper,
.instaDocEditFormWrapper,
.healthcareEditFormWrapper,
.editDoctorsForm {
    .addnew-input {
        width: 275px;
        height: 50px;
        color: #000000;
    }

    .addnew-textArea {
        color: #000000;
        font-size: 13px;
        height: 100px;
    }

    .inlineArrow {
        top: 28%;
        right: -20%;
    }

    /*---------Languages Dynamic Dropdown----------*/
    .dynamic-languages {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        background: #F9F9F9;
        padding: 10px 20px;
        display: flex;
    }

    .dynamic-languages>div:nth-child(1) {
        width: 90%;
        display: flex;
    }

    .dynamic-language-label {
        margin-left: 2%;
        display: flex;
    }

    .dynamic-language-label>div:nth-child(2)>button {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CB1B5B;
        color: #CB1B5B;
        font-size: small;
        background: transparent;
    }

    .dynamic-languages>div:nth-child(2) {
        width: 10%;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .dynamic-languages-dropdown {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 10px 10px;
        transition: 2s;
        position: absolute;
        top: 75px;
        background: #fff;
    }

    .dynamic-languages-dropdown-container {
        display: flex;
        padding: 10px 10px;
    }

    .dynamic-languages-dropdown-text {
        width: 90%;
    }

    .dynamic-languages-dropdown-input {
        width: 10%;
    }

    .dynamic-languages-dropdown-radio {
        width: 100%;
        height: 100%;
        accent-color: #CB1B5B;
    }

    .dynamic-languages-apply {
        width: 100%;
        height: 8vh;
        padding: 10px 10px;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .dynamic-languages-apply div {
        border: 1px solid transparent;
        background: transparent;
        cursor: pointer;
    }
}

.partneredDoctorLanuages {
    // overflow: scroll;

    .addnew-input {
        width: 100%;
    }

    .dynamic-languages-dropdown {
        z-index: 1;
    }

    .inlineArrow {
        right: 2%
    }
}

.plus-icon {
    width: 25px;
    height: 25px;
    background-color: #cb1b5b;
    border-radius: 50%;
    margin-left: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addPlusIcon {
    background-color: #cb1b5b;
    padding: 2px 8px;
    border-radius: 5px;

    img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }
}

.c-input-group-prefix {
    position: absolute;
    left: 10px;
    height: 32px !important;
    min-width: 42px;
    top: 12px;
    z-index: 20;
}

.mobileInput {
    padding-left: 50px;
}


.filterRootModal {
    height: auto;
    width: 229px;
    position: fixed;
    left: 40%;
    top: 25%;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 2s;
    padding: 15px;
    z-index: 5;
    .filter-content-card-doctor {
        height: auto;
        padding: 8px 0;
    }

    .filter-header {
        padding: 15px 0;
    }

    .filter-foot-btn {
        position: relative;
        padding: 10px 0 15px;
    }

}

.filterModal {
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 0.2s;
    animation: animationCard 0.2s linear;
    width: 229px;
    position: fixed;
    padding: 15px;

}

.filterModelNestedLevel {
    left: 58%;
    top: 25%;
}

.filterOptionsModel {
    z-index: 5;
    height: 80vh;
    max-height: 450px;
    // max-height: 80vh;
    overflow-y: scroll;
    scrollbar-color: #cd1f3d #f8f8f8;
}

.filterModalHeader {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 15px;
    padding-bottom: 15px;
}

.filterModalHr {
    color: gray;
    border-bottom: 1px solid rgb(233, 228, 228);
}

.filterModalBox {
    padding: 12px 0 0;
    cursor: pointer;

    >div:nth-child(1) {
        width: 85%;
        font-size: 13px;
        color: #65626C;
    }

    >div:nth-child(2) {
        width: 15%;
    }
}

.filterFooterBtn {
    display: flex;

    button {
        width: 83px;
        height: 34px;
        border-radius: 2px;
        font-size: 13px;
    }

    >button:nth-child(1) {
        border: 1px solid #CB1B5B;
        background: #fff;
        color: #CB1B5B;
    }

    >button:nth-child(2) {
        border: 1px solid #e30d5b;
        background: #CB1B5B;
        color: #fff;
    }
}

.addDoctorDegree {
    border: none;
    color: #CB1B5B;
    background: white;
}

.degreeFieldLabel {
    max-width: 472px;
}

.healthsynergyFormInput {
    height: 50px;
    width: 75%;
}

.addDoctorPlusImg {
    background: #CB1B5B;
    border-radius: 50%;
    height: 18px;
    width: 18px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0 !important;
}