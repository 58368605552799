.home {
    width: 100%;
    height: 100vh;
    background: #fff;
}

.sideMenuWrapper {
    position: fixed;
    top: 84px;
    left: 0;
    bottom: 0;
    width: 300px;
    max-width: 300px;
    height: calc(100vh - 84px);
    overflow: scroll;

    .ant-menu-root {
        padding: 16px 0;
    }

    .ant-menu-item {
        svg {
            color: #797979;
        }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        margin: 0 16px;
        height: 52px !important;
        width: calc(100% - 32px);
    }

    .ant-menu-title-content {
        font-size: 15px;
        font-weight: 600;
        color: #797979;
    }

    .ant-menu-submenu-title {
        .ant-menu-item {
            margin: 1px;
            // width: calc(100% - 32px);
        }
    }

    .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
            .ant-menu-title-content {
                color: #fff;
            }
        }

        svg,
        i {
            color: #fff !important;
        }
    }

    .ant-menu-item-selected {
        background: #CB1B5B;

        .ant-menu-title-content {
            color: #fff;
        }
    }
}

.main-container {
    margin: 84px 0 0 300px;
    padding-bottom: 40px;
    // width: calc(100% - 300px);
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;

    >div {
        width: 95%;
        height: 95%;
    }
}

.container {
    width: 100%;
    height: 90vh;
    display: flex;
}

.sidebar {
    height: 100vh;
    width: 25%;
    transition: all 0.5s;
    margin-top: 2%;
    display: flex;
    justify-content: center;

    >div {
        width: 85%;
        height: 85%;
        // color: #fff;
        background: #181423;
        border-radius: 10px;
        overflow-y: scroll;
        margin-top: 3%;
    }

    // >div::-webkit-scrollbar {
    //     display: none;
    // }
}

.top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
}

.logo {
    font-size: 30px;
}

.bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.link {
    display: flex;
    color: #66636d;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    border-radius: 10px;
    margin-top: 5%;
}

.link:hover {
    background: #464151;
    color: #fff;
    transition: all 0.5s;

    >div:nth-child(2) {
        >select {
            border: 1px solid transparent;
            background: #464151;
            color: #fff;
        }
    }
}

.active {
    background: #464151;
    color: #fff;
}

.icon,
.link_text {
    font-size: 15px;
}

.side-box {
    width: 100%;
    height: 50px;
    display: flex;
    border-radius: 8px;

    >div:nth-child(1) {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >div:nth-child(2) {
        width: 85%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.side-box {
    >div:nth-child(2) {
        >select {
            width: 100%;
            height: 100%;
            background: #181423;
            color: #66636d;
            border: 1px solid transparent;
        }

        >select:active {
            outline: none;
        }
    }
}

.healthsy-arrow {
    margin-left: 10px;
}

.temp {
    width: 100%;
    height: 200px;
    margin-top: -45%;

    >div {
        width: 75%;
        height: 20%;
        overflow: hidden;
        margin-left: 25%;
        display: flex;
        align-items: center;
        color: #66636d;
    }
}

a {
    text-decoration: none;
    font-size: 15px;
    color: #8c8a91;
}

// a{
//     color: #8c8a91;
// }
a:hover {
    color: #8c8a91;
}

.side-dashboard {
    display: flex;
    width: 100%;
    height: 8vh;
    justify-content: center;
    align-items: center;
    margin-top: 5%;

    >div {
        width: 90%;
        height: 90%;
        display: flex;
        // background: #464151;
        // color: #fff;
        border-radius: 8px;

        >div:nth-child(1) {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;

            img {
                opacity: 1;
            }
        }

        >div:nth-child(2) {
            width: 80%;
            display: flex;
            align-items: center;
            font-size: 15px;
        }
    }
}

.side-clinic {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 95%;
        border-bottom: 1px solid #272330;

        >div:nth-child(1) {
            width: 100%;
            height: 8vh;
            display: flex;
            border-radius: 8px;

            >div:nth-child(1) {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;

                >img {
                    opacity: 0.5;
                }
            }

            >div:nth-child(2) {
                width: 80%;
                display: flex;
                align-items: center;
                font-size: 15px;

                span {
                    padding-left: 38%;
                }
            }
        }

        >div:nth-child(2) {
            >div {
                width: 78%;
                height: 6vh;
                margin-left: 20%;
                display: flex;
                align-items: center;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.side-doctor {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 95%;
        border-bottom: 1px solid #272330;

        >div:nth-child(1) {
            width: 100%;
            height: 8vh;
            display: flex;
            border-radius: 8px;

            >div:nth-child(1) {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;

                >img {
                    opacity: 0.5;
                }
            }

            >div:nth-child(2) {
                width: 80%;
                display: flex;
                align-items: center;
                font-size: 15px;

                span {
                    padding-left: 38%;
                }
            }
        }

        >div:nth-child(2) {
            >div {
                width: 78%;
                height: 6vh;
                margin-left: 20%;
                display: flex;
                align-items: center;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.side-healthcare {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 95%;
        border-bottom: 1px solid #272330;

        >div:nth-child(1) {
            width: 100%;
            height: 8vh;
            display: flex;
            border-radius: 8px;

            >div:nth-child(1) {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;

                >img {
                    opacity: 0.5;
                }
            }

            >div:nth-child(2) {
                width: 80%;
                display: flex;
                align-items: center;
                font-size: 15px;

                span {
                    padding-left: 38%;
                }
            }
        }

        >div:nth-child(2) {
            >div {
                width: 78%;
                height: 6vh;
                margin-left: 20%;
                display: flex;
                align-items: center;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.side-healthsynergy {
    width: 100%;
    height: 41vh;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 95%;
        border-bottom: 1px solid #272330;

        >div:nth-child(1) {
            width: 100%;
            height: 8vh;
            display: flex;
            border-radius: 8px;

            >div:nth-child(1) {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;

                >img {
                    opacity: 0.5;
                }
            }

            >div:nth-child(2) {
                width: 80%;
                display: flex;
                align-items: center;
                font-size: 15px;

                span {
                    padding-left: 38%;
                }
            }
        }

        >div:nth-child(2) {
            >div {
                width: 78%;
                height: 6vh;
                margin-left: 20%;
                display: flex;
                align-items: center;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.side-getnotified {
    display: flex;
    width: 100%;
    height: 8vh;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 90%;
        display: flex;
        border-radius: 8px;

        >div:nth-child(1) {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;

            >img {
                opacity: 0.5;
            }
        }

        >div:nth-child(2) {
            width: 80%;
            display: flex;
            align-items: center;
            font-size: 15px;
            // color: #fff;
            text-decoration: none;
        }
    }
}

.side-healtharticle {
    display: flex;
    width: 100%;
    height: 8vh;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 90%;
        display: flex;
        border-radius: 8px;

        >div:nth-child(1) {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;

            >img {
                opacity: 0.5;
            }
        }

        >div:nth-child(2) {
            width: 80%;
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #fff;
        }
    }
}


.side-careers {
    display: flex;
    width: 100%;
    height: 8vh;
    justify-content: center;
    align-items: center;

    >div {
        width: 90%;
        height: 90%;
        display: flex;
        border-radius: 8px;

        >div:nth-child(1) {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;

            >img {
                opacity: 0.5;
            }
        }

        >div:nth-child(2) {
            width: 80%;
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #fff;
        }
    }
}

.temp-arrow {
    padding-left: 30%;
}





//New Css

.common-dash-card {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    >div {
        width: 90%;
        height: 50px;
        border-radius: 10px;
        display: flex;

        >div:nth-child(1) {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                width: 24px;
                height: 24px;
            }
        }

        >div:nth-child(2) {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            color: #8C8A91;
            font-size: 15px;
        }
    }
}

.common-dash-select {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    >div {
        width: 90%;
        height: 50px;
        border-radius: 10px;
        display: flex;

        >div:nth-child(1) {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                width: 24px;
                height: 24px;
                opacity: 0.5;
            }
        }

        >div:nth-child(2) {
            width: 70%;
            height: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            color: #8C8A91;
            font-size: 15px;
            text-overflow: ellipsis;
        }

        >div:nth-child(3) {
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            color: #8C8A91;
            opacity: 0.3;
            cursor: pointer;
        }
    }
}

.common-show-dash {
    width: 100%;

    >div {
        width: 100%;
        color: #8C8A91;
        padding-left: 25%;
        font-size: 13px;
        height: 6vh;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    >hr {
        width: 90%;
        margin: auto;
        border: 1px solid #8C8A91;
    }
}

.submenu-common {
    opacity: 0.5;
}

.webBookings-menu {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// .side-n-setting{
//     margin-bottom: 5%;
// }