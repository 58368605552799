.categoryTab{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
}
.healtharticle-container{
    background-color: #fff;
}
.category-select,.category-input{
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    
    font-size: 13px;
    color:grey;
    padding-left: 0.6rem; 
}
.category-select{
    width: 269px;
    height: 50px;

}
.category-input{
    width: 331px;
    height: 50px;
}

// .category-select {
//     max-height: 40vh;
//     padding: 10px; 
// }

// .category-input::placeholder{
//     text-align: left;
//   font-size:  13px;
// color: #98969D;
// }


.category-btn{
    margin-top: 230px;
    padding: 1rem;
}

.category-table{
    table{
        width: 100%;
    }
    .t-head{
        padding: 20px;
        background-color:#F8F8F8;
    }
    .actions{
        width: 20%;
    }
    table tbody tr td{
        padding: 25px 10px;
        padding-left: 2%;
    }
    table thead tr th{
        border: 1px solid #d4d4d4;

    }
    table thead tr th:nth-child(4){
        width: 10%;
    }
    table thead tr th:nth-child(1){
        width: 3%;
    }
    table thead tr th:nth-child(2){
        width: 10%;
    }
    table thead tr th:nth-child(3){
        width: 10%;
    }
    table thead tr th:nth-child(5){
        width: 10%;
    }
}