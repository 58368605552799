.doctoractivate-card1{
    width: 90%;
    height: 13vh;
    margin-top: 2%;
    display: flex;
    margin-left: 0.5%;
    >div:nth-child(1){
        width: 40%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 80%;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(2){
        width: 30%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 80%;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(3){
        width: 15%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 80%;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(4){
        width: 15%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 80%;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
}

.doctoractivate-card2{
    width: 90%;
    height: 13vh;
    margin-top: 2%;
    display: flex;
    margin-left: 0.5%;
    >div:nth-child(1){
        width: 25%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 115px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(2){
        width: 25%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 166px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(3){
        width: 15%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 250px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
}

.doctoractivate-card3{
    width: 90%;
    height: 13vh;
    margin-top: 2%;
    display: flex;
    margin-left: 0.5%;
    >div:nth-child(1){
        width: 25%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 142px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(2){
        width: 25%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 119px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(3){
        width: 15%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 119px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
}

.doctor-activate-submit{
    width: 100%;
    height: 10vh;
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    >button{
        width: 332px;
        height: 50px;
        background: #3B86FF 0% 0% no-repeat padding-box;
        border: 1px solid #3B86FF;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        border-radius: 4px;
    }
}


//Doctor Track Css

.doctor-track-header{
    width: 90%;
    height: 85px;
    margin-top: 5%;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    >div:nth-child(1){
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        >img{
            width: 48px;
            height: 48px;
            border-radius: 5px;
            border: 1px solid gray;
        }
    }
    >div:nth-child(2){
        width: 60%;
        >div:nth-child(1){
            margin-top: 3%;
            font-size: 15px;
            font-weight: 700;
            color: #181423;
        }
        >div:nth-child(2){
            margin-top: 1%;
            font-size: 13px;
            color: #4B4853;    
        }
    }
    >div:nth-child(3){
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #CB185B;
        font-size: 15px;
    }
}

.doctor-track-data{
    width: 90%;
    height: 15vh;
    margin-top: 3%;
    display: flex;
    margin-left: 1%;
    >div:nth-child(1){
        width: 30%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 222px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(2){
        width: 30%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 222px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
    >div:nth-child(3){
        width: 30%;
        height: 100%;
        >div:nth-child(1){
            color: #4B4853;
            font-size: 15px;
            margin-bottom: 5%;
        }
        >div:nth-child(2){
            width: 222px;
            height: 50px;
            border: 1px solid #D4D4D4;
            font-size: 15px;
            color: #65626C;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 5%;
            border-radius: 5px;
        }
    }
}


.doctor-track-second-portion{
    margin-bottom: 10%;
}

.doctor-track-view{
    color: #72bb81;
    cursor: pointer;
}
.doctor-track-cancel{
    color: #ff6058;
    cursor: pointer;
    padding-left: 5%;
}
.doctor-track-reschedule{
    color: #0b6cc2;
    cursor: pointer;
    padding-left: 5%;
}
.doctor-track-redirect{
    color: #d6d63b;
    cursor: pointer;
    padding-left: 5%;
}
.doctor-track-invoice{
    color: #191524;
    cursor: pointer;
    padding-left: 5%;    
}

.doctor-revenue-section{
    margin-top: 5%;
    width: 95%;
    margin: auto;
    height: 40vh;
    display: flex;
    align-items: center;
    gap: 50px;
    >div:nth-child(1){
        width: 290px;
        height: 136px;
        background: #F2F7FF 0% 0% no-repeat padding-box;
        border-radius: 15px;
        position: relative;
        >img{
            width: 32px;
            height: 32px;
            margin: auto;
            margin-left: 45%;
            margin-top: 5%;
            border: 1px solid gray;
        }
        >div:nth-child(2){
            color: #181423;
            font-size: 13px;
            text-align: center;
            padding-top: 2%;
        }
        >div:nth-child(3){
            color: #181423;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            padding-top: 2%;
        }
        >:nth-child(4){
            font-size: 20px;
            position: absolute;
            top: 2%;
            right: 5%;
            cursor: pointer;
        }
    }
    >div:nth-child(2){
        width: 290px;
        height: 136px;
        background: #F2F7FF 0% 0% no-repeat padding-box;
        border-radius: 15px;
        position: relative;
        >img{
            width: 32px;
            height: 32px;
            margin: auto;
            margin-left: 45%;
            margin-top: 5%;
            border: 1px solid gray;
        }
        >div:nth-child(2){
            color: #181423;
            font-size: 13px;
            text-align: center;
            padding-top: 2%;
        }
        >div:nth-child(3){
            color: #181423;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            padding-top: 2%;
        }
        >:nth-child(4){
            font-size: 20px;
            position: absolute;
            top: 2%;
            right: 5%;
            cursor: pointer;
        }
    }
}

.doctor-arrow1{
    width: 91px;
    height: 123px;
    background: #f8f8f8;
    box-shadow: 0px 1px 2px #0000001A;
    border-radius: 5px;
    position: absolute;
    top: 15%;
    right: 5%;
    >div{
        font-size: 13px;
        color: #181423;
        height: 18%;
        padding-left: 5%;
        padding-top: 2%;
        font-weight: 700;
    }
}

.doc-label{
    font-weight: 700;
}

.sortby-doctor-track{
    top: 118%;
}