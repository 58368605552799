.blog-subscribe-table {
  table {
    width: 100%;
  }

  table thead tr td:nth-child(1) {
    width: 60px;
  }

  table thead tr td:nth-child(2) {
    width: 300px;
  }

  table thead tr td:nth-child(3) {
    width: 450px;
  }
}

.subs {
  cursor: pointer;
  color: #88c594;
}

.subs-logo {
  padding-left: 3%;
}

.subs-highlight {
  font-weight: bold;
}

.unsubs {
  cursor: pointer;
  padding-left: 3%;
  color: #ff564c;
}

.unsubs-highlight {
  font-weight: bold;
}