.settings-social-media-links{
    table{
        width: 150% !important;
    }
    table thead tr td:nth-child(1){
        width: 60px !important;
    }
    table thead tr td:nth-child(2){
        width: 180px;
    }
    table thead tr td:nth-child(3){
        width: 180px;
    }
    table thead tr td:nth-child(4){
        width: 180px;
    }
    table thead tr td:nth-child(5){
        width: 180px;
    }
    table thead tr td:nth-child(6){
        width: 300px;
    }
}