@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");

* {
  font-family: "Mulish", sans-serif;
  font-family: "Mulish", sans-serif;
}

.careers-tab {
  width: 100%;
  height: 10vh;
  display: flex;
  font-size: 15px;
  background: #fff;
  width: 100%;
  height: 10vh;
  display: flex;
  font-size: 15px;
  background: #fff;

  > div {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 1px;
    cursor: pointer;
  }
  > div {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 1px;
    cursor: pointer;
  }

  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4) {
    margin-left: 7%;
    color: #65626c;
  }
  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4) {
    margin-left: 7%;
    color: #65626c;
  }
}

.healtharticle-title {
  height: auto !important;
  font-size: 15px;
  font-weight: bold;
  padding-top: 2% !important;
  height: auto !important;
  font-size: 15px;
  font-weight: bold;
  padding-top: 2% !important;
}

.careers-car-tab {
  margin-bottom: 3%;
  margin-bottom: 3%;
}

.career-tab {
  border-bottom: 3px solid #cb1b5b;
  color: #cb1b5b;
  border-bottom: 3px solid #cb1b5b;
  color: #cb1b5b;
}

.health-carrer-title {
  margin-left: 1.6rem !important;
  margin-left: 1.6rem !important;
}

.career-main-table {
  overflow-x: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  max-height: 600px;
  overflow-y: auto;
}

.career-main-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.875rem;
  text-align: left;
  border: 1px solid #e5e7eb;
}

.career-main-table table thead tr td {
  background-color: #f7f6f6;
  font-weight: bold;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  border-bottom: 1px solid #d1d5db;
  border-right: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  z-index: 1;
}

.career-main-table table tbody tr td {
  padding: 1rem 1.5rem;
  font-size: 15px;
  white-space: nowrap;
  border-bottom: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}

.career-main-table table tbody tr td:nth-child(8),
.career-main-table table tbody tr td:nth-child(10) {
  max-width: 350px;
  word-wrap: break-word;
  white-space: normal;
}

.career-main-table table thead tr td:last-child,
.career-main-table table tbody tr td:last-child {
  border-right: none;
}

.career-main-table table tbody tr:last-child td {
  border-bottom: none;
}

.career-main-table table tbody tr:hover {
  background-color: #f3f4f6;
}

.career-main-table table tbody tr td:nth-child(2) {
  font-weight: 500;
  color: #111827;
}

.career-main-table table a {
  text-decoration: none;
}

.career-main-table table a:hover {
  text-decoration: underline;
}

.downloadBtn {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  background: none;
  border: none;
  padding: 1;
}

.downloadBtn .ml-2 {
  margin-left: 0.5rem;
}

@media (max-width: 640px) {
  .career-main-table table thead tr td,
  .career-main-table table tbody tr td {
    padding: 0.75rem 1rem;
  }
}

// contact
.career-contact {
  color: #1271c4;
  cursor: pointer;
  color: #1271c4;
  cursor: pointer;
}

.career-contact-bold {
  font-weight: bold;
  font-weight: bold;
}

// reject
.career-reject {
  padding-left: 2%;
  color: #ff7068;
  cursor: pointer;
  padding-left: 2%;
  color: #ff7068;
  cursor: pointer;
}

.career-reject-bold {
  font-weight: bold;
  font-weight: bold;
}

//select
.career-select {
  padding-left: 2%;
  color: #75bc84;
  cursor: pointer;
  padding-left: 2%;
  color: #75bc84;
  cursor: pointer;
}

.career-select-bold {
  font-weight: bold;
  font-weight: bold;
}

//interview
.career-interview {
  padding-left: 2%;
  color: #cccc0c;
  cursor: pointer;
  padding-left: 2%;
  color: #cccc0c;
  cursor: pointer;
}

.career-interview-bold {
  font-weight: bold;
  font-weight: bold;
}

//not contacted
.career-not-contact {
  padding-left: 2%;
  color: #cccc0c;
  cursor: pointer;
  padding-left: 2%;
  color: #cccc0c;
  cursor: pointer;
}

.career-not-contact-icon {
  padding-left: 3%;
  padding-left: 3%;
}

.career-not-contact-bold {
  font-weight: bold;
  font-weight: bold;
}

//Sort By portion

.sortby-Common-career-main {
  width: 142px;
  height: 178px;
  position: absolute;
  right: 12%;
  top: 45%;
  right: 250px;
  top: 300px;
  z-index: 10;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 10px #47474729;
  border-radius: 5px;
  width: 142px;
  height: 178px;
  position: absolute;
  right: 12%;
  top: 45%;
  right: 250px;
  top: 300px;
  z-index: 10;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 10px #47474729;
  border-radius: 5px;

  > div {
    width: 80%;
    margin: auto;
    height: 25%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 13px;
    color: #65626c;
    cursor: pointer;
  }
  > div {
    width: 80%;
    margin: auto;
    height: 25%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 13px;
    color: #65626c;
    cursor: pointer;
  }
}

.downloadBtn {
  background: #cb1b5b;
  color: #fff;
  border: 1px solid #9e0f44;
  border-radius: 10px;
  background: #cb1b5b;
  color: #fff;
  border: 1px solid #9e0f44;
  border-radius: 10px;

  svg {
    margin-left: 4px;
  }
  svg {
    margin-left: 4px;
  }
}
//filter portion
.filter-container-career {
  width: 229px;
  height: 385px;
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 10;
  box-shadow: 0px 1px 10px #47474729;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  transition: 2s;
}

// Doctor Filter Fee
.filter-doctor-fee-career {
  width: 229px;
  position: fixed;
  left: 58%;
  top: 35%;
  z-index: 10;
  box-shadow: 0px 1px 10px #47474729;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  transition: 0.2s;
  animation: animationCard 0.2s linear;
}
