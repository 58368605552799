.viewBtn {
    width: 200px !important;

    >div:nth-child(2) {
        font-size: 25px;
    }
}

.highlight {
    font-weight: bold;
}

.viewContentSection {
    .addBtn {
        width: 260px;
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: #e8f1ff;
        cursor: pointer;

        >div:nth-child(1) {
            width: 40px;
            height: 90%;
            display: flex;
            justify-content: right;
            align-items: center;
            font-size: 30px;
            color: #65a0ff;
            border: 1px solid #e8f1ff;
            background: #e8f1ff;
            // border: 1px solid red;
        }

        >div:nth-child(2) {
            width: 80%;
            height: 90%;

            // border: 1px solid red;
            button {
                width: 100%;
                height: 100%;
                border: 1px solid #e8f1ff;
                background: #e8f1ff;
                color: #3b86ff;
                font-size: 14px;
            }
        }
    }
}