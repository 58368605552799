.depart{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
}
.department-function .department-fun{
    width: 2%;
    text-align: center;
    padding-bottom: 1em;
}
.department-function .position{
    width: 1%;
    text-align: center;
    padding-bottom: 1em;
}
.department-function .serial{
    width: 0.3%;
    text-align: center;
    padding-bottom: 1em;
}
.department-function .action{
    width: 10%;
    text-align: left;
    padding-bottom: 2em;
    padding-left: 1em;
    
}
.department-function td{
    height: 70px;
}

.search{
    position: relative;

}
.icon{
    position: absolute;
    bottom:8px;
    left: 6px;
    color:grey;
    
}

// input::placeholder{
//     padding-left: 1em;
//     font-size: 0.5rem;
//     font-weight: 300;
// }
.search input{
    width: 35%;
}
select{
    border: none;
    outline: none;
}
.career-department-table{
    width: 97%;
    margin: auto;
    // margin-top: 2%;
    border-radius: 8px;
    >table{
        width: 100%;
        >thead tr td:nth-child(1){
            width: 6%;
            border-top-left-radius: 8px;
            padding: 10px 10px;
        }
        >thead tr td:nth-child(2){
            width: 15%;
            padding: 10px 10px;
        }
        // >thead tr td:nth-child(3){
        //     width: 20%;
        //     padding: 10px 10px;
        // }
        >thead tr td:nth-child(3){
            width: 59%;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }
        >thead tr td{
            font-weight: bold;
            font-size: 13px;
            background: #f7f6f6;
            border: 1px solid #D4D4D4;
        }
        >tbody tr td{
            font-size: 13px;
            padding-left: 2%;
            border: 1px solid #D4D4D4;
        }
    }
}