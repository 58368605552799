.career-tab-comment{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B;
}

.post-a-commet-table{
    table{
        width: 120% !important;
    }
    table thead tr th:nth-child(1){
        width: 60px;
    }
    table thead tr th:nth-child(2){
        width: 150px;
    }
    table thead tr th:nth-child(3){
        width: 150px;
    }
    table thead tr th:nth-child(4){
        width: 250px;
    }
    table thead tr th:nth-child(5){
        width: 150px;
    }
    table thead tr th:nth-child(6){
        width: 200px;
    }
    table thead tr th:nth-child(7){
        width: 300px;
    }
}

.post-comment-delete{
    color: #ff5e54;
    cursor: pointer;
}

.post-comment-view{
    padding-left: 5%;
    color: #88c594;
    cursor: pointer;
}

.post-comment-delete-logo{
    padding-left: 5%;   
}

.post-comment-view-logo{
    padding-left: 5%;
}

.sortby-popup-comments{
    top: 40%;
}