.retail-pharmacy-track-table{
    table{
        width: 150%;
    }
    table thead tr td:nth-child(1){
        width: 60px
    }
    table thead tr td:nth-child(2){
        width: 150px
    }
    table thead tr td:nth-child(3){
        width: 150px
    }
    table thead tr td:nth-child(4){
        width: 150px
    }
    table thead tr td:nth-child(5){
        width: 150px
    }
    table thead tr td:nth-child(6){
        width: 150px
    }
    table thead tr td:nth-child(7){
        width: 150px
    }
    table thead tr td:nth-child(8){
        width: 150px
    }
    table thead tr td:nth-child(9){
        width: 150px
    }
    table thead tr td:nth-child(10){
        width: 150px
    }
    table thead tr td:nth-child(11){
        width: 500px
    }
}