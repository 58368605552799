.home-dashboard {
    width: 100%;
    height: 100%;
}

.home-dashboard-box {
    width: 100%;
    margin-top: 3%;
}

.home-dashboard-title {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    font-weight: 700;
    background-color: #fff;
    padding-left: 16px;
    border-radius: 10px;
}

.home-dashboard-overall {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 25px;
    background: white;
    margin-top: 24px;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.home-dashboard-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 40px;
    background-color: #fff;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    >div {
        height: 136px;
        border-radius: 15px;
        text-align: center;
        padding: 1.5rem;

        >div:nth-child(1) {
            width: 100%;
            height: 40%;
            display: flex;
            font-size: 25px;
            justify-content: center;
            align-items: center;
            margin-top: -3%;
        }

        >div:nth-child(2) {
            width: 100%;
            height: 30%;
            font-size: 15px;
        }

        >div:nth-child(3) {
            width: 100%;
            height: 30%;
            font-size: 25px;
            font-weight: 900;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    >div>div>img {
        height: 32px;
        width: 32px;
    }

    >div {
        >div:nth-child(2) {
            margin-top: 8px;
        }

        >div:nth-child(3) {
            margin-top: 8px;
        }
    }
}