.meta-tags-scroll{
    width: 95%;
    height: 80vh;
    margin-left: 1%;
    overflow: scroll;
    overflow-x: hidden;
}

// .meta-tags-scroll::-webkit-scrollbar{
//     display: none;
// }

.meta-tag-file{
    width: 360px;
    border: 1px solid #D4D4D4;
    padding: 10px 10px;
    border-radius: 8px;
    >input{
        border-radius: 4px;
    }
}
.submit-btn{
    margin-right: 12%;
}