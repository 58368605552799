.doctor-page-tick {
    width: 97%;
    margin: auto;
    height: 10vh;
    margin-top: 2%;
    display: flex;
    border-bottom: 1px solid rgb(242, 239, 239);

    >div:nth-child(1) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 3px solid #6eb17b;

        >img {
            width: 24px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(2) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 30px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }

    >div:nth-child(3) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 30px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }

    >div:nth-child(4) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 30px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }
}

.doctor-page2-tick {
    width: 97%;
    margin: auto;
    height: 10vh;
    margin-top: 2%;
    display: flex;
    border-bottom: 1px solid rgb(242, 239, 239);

    >div:nth-child(1) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;

        >img {
            width: 24px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(2) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;
        border-bottom: 3px solid #6eb17b;

        >img {
            width: 24.5px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(3) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 27px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }

    >div:nth-child(4) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 30px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }
}

.doctor-page3-tick {
    width: 97%;
    margin: auto;
    height: 10vh;
    margin-top: 2%;
    display: flex;
    border-bottom: 1px solid rgb(242, 239, 239);

    >div:nth-child(1) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;

        >img {
            width: 24px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(2) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 24.5px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(3) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;
        border-bottom: 3px solid #6eb17b;

        >img {
            width: 27px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(4) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 30px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }
}

.doctor-page4-tick {
    width: 97%;
    margin: auto;
    height: 10vh;
    margin-top: 2%;
    display: flex;
    border-bottom: 1px solid rgb(242, 239, 239);

    >div:nth-child(1) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;

        >img {
            width: 24px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(2) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 24.5px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(3) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 24.5px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(4) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;
        border-bottom: 3px solid #6eb17b;

        >img {
            width: 27px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }
}

.pharm-nxt1 {
    background: #3B86FF;
    width: 166px;
    margin-left: 7%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 95%;
        height: 95%;
        display: flex;

        >div:nth-child(1) {
            width: 70%;
            height: 100%;

            >button {
                width: 100%;
                height: 100%;
                border: 1px solid transparent;
                background: transparent;
                color: #fff;
            }
        }

        >div:nth-child(2) {
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.addnewpharm3-title {
    font-weight: bold;
}