.master-doctor{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
}

.master-doctor-table{
    table{
        width: 100%;
    }
}

.master-edit{
    color: #0267c1;
    cursor: pointer;
}
.master-edit-logo{
    margin-left: 3%;
}
.master-delete{
    color: #ff5349;
    padding-left: 5%;
    cursor: pointer;
}
.master-delete-logo{
    margin-left: 3%;
}
.master-view{
    color: #80c18d;
    padding-left: 5%;
    cursor: pointer;
}
.master-view-logo{
    margin-left: 3%;
}