.career-days{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
}

.master-doctor-table{
    table{
        width: 100%;
    }
}

.sortby-master-common{
    top: 65%;
}