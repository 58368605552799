.menuTab{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B;
}

.new-menu-btn{
   background-color:#E8F1FF;
   color: #3B86FF;
   width: 242px;
   height: 50px;
   border: none;
}
.new-category-btn{
    background-color: #fff;
    border: 1px solid  #3B86FF;
    width: 242px;
    height: 50px;
    border-radius: 5px;
    color: #3B86FF;
    
}
.add-img{
    margin-right: 1rem;
}
.td-Menu {
    width: 4% !important;
}
.td-sno{
    width: 1% !important;
}
.td-category{
 width: 2% !important;
}
.td-status{
    width: 2% !important;
}

.menu-show{
    margin-top: 2rem;
}

.search{
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 201px;
height: 40px;
};
.value{
    height: 15px;
}
.input-menu{
    border: 1px solid #D4D4D4;
    border-radius: 5px
}
.add-button{
width: 166px;
height: 50px;
background: var(--unnamed-color-54a464) 0% 0% no-repeat padding-box;
background: #54A464 0% 0% no-repeat padding-box;
border-radius: 4px;
border: none;
color: #F8F8F8;

}
.new-menu{
    height: 53vh;
}
.Add-Btn{
    margin-left: 300px;
    margin-top: 30px;
    margin-bottom: 2rem;
   
}

.menu-table{
    table{
        width: 100%;
    }
    .t-head{
        padding: 20px;
        background-color:#F8F8F8;
    }
    .total-category{
        width: 25%;
    }
    .status{
        width: 15%;
    }
    table tbody tr td{
        padding: 30px 10px;
        padding-left: 2%;
    }
    table thead tr th{
        font-size: 13px;
    }
    table thead tr th:nth-child(1){
        width: 3%;
    }
}