.add-new-clinic-search {
  display: flex;
  width: 412px;
  height: 50px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  > div:nth-child(1) {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div:nth-child(2) {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > input {
      width: 85%;
      border: 1px solid transparent;
      background: transparent;
      color: #65626c;
      font-size: 13px;
    }
  }
  > div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.search-cancel {
  width: 16px;
  height: 16px;
  opacity: 0.5;
  color: #4b4853;
  cursor: pointer;
}

.search-show-data {
  width: 412px;
  margin-top: 2%;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.search-show-box {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  margin-bottom: 5%;
  border-bottom: 1px solid #e9eaf1;
  > div:nth-child(1) {
    width: 20%;
    > img {
      width: 40px;
      height: 40px;
    }
  }
  > div:nth-child(2) {
    width: 60%;
    > div:nth-child(1) {
      color: #181423;
      font-size: 15px;
      font-weight: 700;
    }
    > div:nth-child(2) {
      background: #f3f3f3 0% 0% no-repeat padding-box;
      border-radius: 4px;
      font-size: 13px;
      color: #4b4853;
      width: 139px;
      height: 29px;
      margin-top: 2%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > div:nth-child(3) {
      margin-top: 2%;
      color: #4b4853;
      font-size: 13px;
    }
    > div:nth-child(4) {
      margin-top: 2%;
      color: #4b4853;
      font-size: 13px;
    }
  }
  > div:nth-child(3) {
    width: 20%;
    > div {
      width: 76px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      margin-top: 90%;
      > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > div:nth-child(2) {
        font-size: 13px;
        color: #d9d9d9;
      }
    }
  }
}
