.business-button {
    margin-top: 3% !important;
}

.logoFileChoose {
    width: 125px;
}

.blog-search-banner-button {
    margin: 35px 25% 0 0 !important;
}

.bannerImg {
    width: 340px;
    height: 190px;
    margin-top: 2%;
    margin-bottom: 5%;
}