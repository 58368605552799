.add-new-retail-pharmacy-table {
  table {
    width: 150%;
  }
  table thead tr td:nth-child(1) {
    width: 60px;
  }
  table thead tr td:nth-child(2) {
    width: 150px;
  }
  table thead tr td:nth-child(3) {
    width: 120px;
  }
  table thead tr td:nth-child(4) {
    width: 200px;
  }
  table thead tr td:nth-child(5) {
    width: 200px;
  }
  table thead tr td:nth-child(6) {
    width: 200px;
  }
  table thead tr td:nth-child(7) {
    width: 200px;
  }
  table thead tr td:nth-child(8) {
    width: 200px;
  }
  table thead tr td:nth-child(9) {
    width: 200px;
  }
  table thead tr td:nth-child(10) {
    width: 150px;
  }
}

.tamilnadu {
  width: 130px;
  height: 31px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-left: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #181423;
  font-size: 13px;
  > select {
    background: transparent;
    border: 1px solid transparent;
  }
}
