.site-map-text-area{
    width: 100%;
    height: 20vh;
    background: transparent;
    border-radius: 8px;
}
.preview-file-input{
    color: black;
    display: inline-block;
    background: #cccccc;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 10px 25px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 10pt;
}