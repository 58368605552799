// @import url('https://fonts.googleapis.com/css2?family=Anton&family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;400&display=swap');

.adminpanellogin {
  * {
    font-family: "Mulish", sans-serif;
  }

  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;

  .adminpanel-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    background-image: url("../../assets/images/login_img.svg");
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
  }

  .adminpanel-logo {
    height: 100%;
    width: 100%;
  }

  .adminpanel-right {
    min-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adminpanel-right-box {
    width: 100%;
    max-width: 412px;
    height: 487px;
  }

  .adminpanel-right-header-logo {
    width: 100%;
    height: 15%;
  }

  .adminpanel-right-title {
    width: 100%;

    >div:nth-child(1) {
      width: 100%;
      height: 70%;
      font-size: 20px;
      display: flex;
      color: #65626C;
      padding: 16px 0 60px;
    }

    >div:nth-child(2) {
      width: 100%;
      height: 30%;
      font-size: 13px;
      color: #aeadb2;
      margin-top: -1%;

      span {
        color: #cb1b5b;

        a {
          text-decoration: none;
          color: #cb1b5b;
          margin-bottom: 2%;
          font-size: 13px;
          font-weight: 700;
          margin-left: 2%;
          border-bottom: 2px solid #cb1b5b;
        }
      }
    }
  }

  .adminpanel-right-form {
    width: 100%;
    height: 65%;
  }

  .adminpanel-right-username {
    width: 100%;
    height: 25%;

    >div:nth-child(1) {
      width: 100%;
      height: 20%;
      font-size: 15px;
      color: #4b4853;
      font-weight: 500;
    }

    >div:nth-child(2) {
      width: 100%;
      height: 50px;
      border: 1px solid #e3e3e3;
      font-size: 15px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2%;

      >input {
        width: 95%;
        height: 80%;
        border: 1px solid transparent;
        font-size: 15px;
        font-weight: 500;
        color: #181423;
      }
    }
  }

  .star-red {
    color: red;
  }

  input:focus {
    outline: none;
  }

  .adminpanel-right-password {
    width: 100%;
    height: 25%;
    margin-top: 16px;

    >div:nth-child(1) {
      width: 100%;
      height: 20%;
      font-size: 15px;
      color: #4b4853;
      font-weight: 500;
    }

    >div:nth-child(2) {
      width: 100%;
      height: 50px;
      border: 1px solid #e3e3e3;
      font-size: 15px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2%;

      >div:nth-child(1) {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >input {
          width: 100%;
          height: 80%;
          background: transparent;
          border: 1px solid transparent;
          font-size: 15px !important;
          padding-left: -5%;
          font-weight: 500;
          color: #181423;
        }
      }

      >div:nth-child(2) {
        width: 20%;
        height: 100%;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #aeadb2;
      }
    }
  }

  .adminpanel-right-forgot {
    display: flex;
    margin: 24px 0 46px;
    width: 100%;
    max-width: 400px;

    >div:nth-child(1) {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;

      >div:nth-child(1) {
        >input {
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }

        >input::after:checked {
          background: #cb1b5b;
          border: 1px solid #cb1b5b;
        }
      }

      >div:nth-child(2) {
        font-size: 15px;
        margin-left: 4%;
        margin-bottom: 2%;
        font-weight: 500;
        color: #4b4853;
      }
    }

    >div:nth-child(2) {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      color: #cb1b5b;
      font-size: 12px;
      font-weight: Mulish;
      cursor: pointer;
    }
  }

  .adminpanel-right-button {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    >button {
      width: 100%;
      max-width: 400px;
      height: 56px;
      border-radius: 12px;
      color: white;
      border: none;
      background: #FAE8EF;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .login-username::-webkit-input-placeholder,
  .login-password::-webkit-input-placeholder {
    font-weight: lighter;
    color: gray;
  }

  .login-username::-moz-placeholder,
  .login-password::-moz-placeholder {
    font-weight: lighter;
    color: gray;
  }

  .login-username::-ms-input-placeholder,
  .login-password::-ms-input-placeholder {
    font-weight: lighter;
    color: gray;
  }

  //input type checkbox style
  .admin-container {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 82%;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .admin-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .admin-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 3px;
  }

  .admin-container:hover input~.admin-checkmark {
    background-color: #fff;
  }

  .admin-container input:checked~.admin-checkmark {
    background-color: #ba1752;
  }

  .admin-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .admin-container input:checked~.admin-checkmark:after {
    display: block;
  }

  .admin-container .admin-checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .cursorPointer {
    cursor: pointer;
  }

  .adminpanel-login-img {
    width: 100%;
    max-width: 510px;
    height: 592px;
    border-radius: 24px;
  }

  .adminpanel-login-healthsyImg {
    width: 100%;
    max-width: 367px;
    height: 93px;
  }

  .adminpanel-login-image {
    align-items: center;
    backdrop-filter: blur(24px);
    justify-content: center;
    display: flex;
    position: absolute;
    box-shadow: none !important;
    width: 100%;
    max-width: 510px;
    height: 592px;
    box-shadow: none !important;
  }

  .adminpanel-login-title {
    font-weight: 700;
    font-size: 40px;
  }

  .adminpanel-input-placeholder {
    color: #181423;
  }

  label span {
    color: #D2D2D2;
  }

  .adminpanel-loggedIn {
    font-size: 12px;
    color: #797979;
  }

  .adminpanel-placeholder {
    font-size: 12px;
  }

  .pure-material-textfield-outlined {
    --pure-material-safari-helper1: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    position: relative;
    display: inline-block;
    padding-top: 6px;
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
  }

  /* Input, Textarea */
  .pure-material-textfield-outlined>input,
  .pure-material-textfield-outlined>textarea {
    box-sizing: border-box;
    margin: 0;
    border: solid 1px;
    /* Safari */
    border-color: #D2D2D2;
    border-top-color: transparent;
    border-radius: 4px;
    padding: 15px 13px 15px;
    width: 100%;
    height: inherit;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    background-color: transparent;
    box-shadow: none;
    /* Firefox */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    transition: border 0.2s, box-shadow 0.2s;
  }

  /* Span */
  .pure-material-textfield-outlined>input+span,
  .pure-material-textfield-outlined>textarea+span {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border-color: #D2D2D2;
    width: 100%;
    max-height: 100%;
    color: #797979;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
  }

  /* Corners */
  .pure-material-textfield-outlined>input+span::before,
  .pure-material-textfield-outlined>input+span::after,
  .pure-material-textfield-outlined>textarea+span::before,
  .pure-material-textfield-outlined>textarea+span::after {
    content: "";
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    border-top: solid 1px;
    border-color: #D2D2D2;
    min-width: 10px;
    height: 8px;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border-color 0.2s, box-shadow 0.2s;
  }

  .pure-material-textfield-outlined>input+span::before,
  .pure-material-textfield-outlined>textarea+span::before {
    margin-right: 6px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
  }

  .pure-material-textfield-outlined>input+span::after,
  .pure-material-textfield-outlined>textarea+span::after {
    flex-grow: 1;
    margin-left: 11px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
  }

  .pure-material-textfield-outlined:hover>input+span::before,
  .pure-material-textfield-outlined:hover>textarea+span::before,
  .pure-material-textfield-outlined:hover>input+span::after,
  .pure-material-textfield-outlined:hover>textarea+span::after {
    border-color: #D2D2D2;
  }

  .pure-material-textfield-outlined:hover>input:not(:focus):placeholder-shown,
  .pure-material-textfield-outlined:hover>textarea:not(:focus):placeholder-shown {
    border-color: #D2D2D2;
  }

  /* Placeholder-shown */
  .pure-material-textfield-outlined>input:not(:focus):placeholder-shown,
  .pure-material-textfield-outlined>textarea:not(:focus):placeholder-shown {
    border-color: #D2D2D2;
  }

  .pure-material-textfield-outlined>input:not(:focus):placeholder-shown+span,
  .pure-material-textfield-outlined>textarea:not(:focus):placeholder-shown+span {
    font-size: inherit;
    line-height: 68px;
  }

  .pure-material-textfield-outlined>input:not(:focus):placeholder-shown+span::before,
  .pure-material-textfield-outlined>textarea:not(:focus):placeholder-shown+span::before,
  .pure-material-textfield-outlined>input:not(:focus):placeholder-shown+span::after,
  .pure-material-textfield-outlined>textarea:not(:focus):placeholder-shown+span::after {
    border-top-color: transparent;
  }

  /* Focus */
  .pure-material-textfield-outlined>input:focus,
  .pure-material-textfield-outlined>textarea:focus {
    border-color: #CB1B5B;
    border-top-color: transparent;
    outline: none;
  }

  .pure-material-textfield-outlined>input:focus+span,
  .pure-material-textfield-outlined>textarea:focus+span {
    color: #797979;
  }

  .pure-material-textfield-outlined>input:focus+span::before,
  .pure-material-textfield-outlined>input:focus+span::after,
  .pure-material-textfield-outlined>textarea:focus+span::before,
  .pure-material-textfield-outlined>textarea:focus+span::after {
    border-top-color: #CB1B5B;
  }

  /* Disabled */
  .pure-material-textfield-outlined>input:disabled,
  .pure-material-textfield-outlined>input:disabled+span,
  .pure-material-textfield-outlined>textarea:disabled,
  .pure-material-textfield-outlined>textarea:disabled+span {
    border-color: #D2D2D2;
    border-top-color: transparent !important;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    pointer-events: none;
  }

  .pure-material-textfield-outlined>input:disabled+span::before,
  .pure-material-textfield-outlined>input:disabled+span::after,
  .pure-material-textfield-outlined>textarea:disabled+span::before,
  .pure-material-textfield-outlined>textarea:disabled+span::after {
    border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
  }

  .pure-material-textfield-outlined>input:disabled:placeholder-shown,
  .pure-material-textfield-outlined>input:disabled:placeholder-shown+span,
  .pure-material-textfield-outlined>textarea:disabled:placeholder-shown,
  .pure-material-textfield-outlined>textarea:disabled:placeholder-shown+span {
    border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
  }

  .pure-material-textfield-outlined>input:disabled:placeholder-shown+span::before,
  .pure-material-textfield-outlined>input:disabled:placeholder-shown+span::after,
  .pure-material-textfield-outlined>textarea:disabled:placeholder-shown+span::before,
  .pure-material-textfield-outlined>textarea:disabled:placeholder-shown+span::after {
    border-top-color: transparent !important;
  }

  .adminpanel-left-box{
    display: grid;
    place-items: center;
  }

  @media screen and (max-width: 1440px) {
    .adminpanel-login-image {
      max-width: 401px;
      height: 460px;
    }
  }
  @media screen and (max-width: 1399px) {
    .adminpanel-right {
      min-width: 45%;
    }
  }
}