.healtharticle {
  width: 100%;
  background: #fff;
}

.healtharticle-container {
  width: 100%;
  margin-top: 2%;
}

.healtharticle-title {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: 1.25%;
  font-weight: 700;
  font-size: 15px;

  >span:nth-child(4) {
    padding-left: 0.3rem;
  }

  >span:nth-child(3) {
    padding-left: 0.3rem;
  }

  >span:nth-child(2) {
    padding-left: 0.3rem;
    font-weight: bold;
  }

  >span:nth-child(5) {
    padding-left: 0.3rem;
  }
}

.healtharticle-addnew {
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  margin-left: 2%;
  margin-bottom: 1%;
}

.addnewhealth {
  width: 230px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #e8f1ff;
  cursor: pointer;

  >div:nth-child(1) {
    width: 40px;
    height: 90%;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 30px;
    color: #65a0ff;
    border: 1px solid #e8f1ff;
    background: #e8f1ff;
    // border: 1px solid red;
  }

  >div:nth-child(2) {
    width: 80%;
    height: 90%;

    // border: 1px solid red;
    button {
      width: 100%;
      height: 100%;
      border: 1px solid #e8f1ff;
      background: #e8f1ff;
      color: #3b86ff;
      font-size: 14px;
    }
  }
}

.addNewbtn {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #e8f1ff;
  cursor: pointer;

  >div:nth-child(2) {
    button {
      border: 1px solid #e8f1ff;
      background: #e8f1ff;
      color: #3b86ff;
      font-size: 14px;
    }
  }
}

.managehealth {
  width: 320px;
  height: 50px;
  margin-left: 2%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #65a0ff;
  cursor: pointer;

  >div:nth-child(1) {
    width: 290px;
    height: 90%;

    button {
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      background: #fff;
      color: #65a0ff;
      font-size: 14px;
    }
  }

  >div:nth-child(2) {
    width: 30px;
    height: 90%;
    display: flex;
    align-items: center;
    font-size: 25px;
    color: #65a0ff;
    background: #fff;
    font-size: 15px;
    padding-right: 1rem;
    margin-right: 0.7rem;
  }
}

.healtharticle-show {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  margin-left: 2%;

  >div:nth-child(1) {
    height: 60%;
    width: 4%;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #65626c;
    // margin-left: 2%;
  }

  >div:nth-child(2) {
    width: 5%;
    height: 60%;
    border-radius: 4px;

    >select {
      width: 100%;
      height: 50%;
      font-size: 13px;
      border: 1px solid transparent;
      background: #f8f8f8;
      color: #181423;
      align-items: center;
      margin-top: 11px;
      font-weight: bolder;

    }
  }

  >div:nth-child(3) {
    height: 60%;
    width: 7%;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-left: 2%;
    color: #65626c;

  }

  >div:nth-child(4) {
    width: 201px;
    height: 40px;
    margin-left: 3%;
    display: flex;
    background: #f8f8f8;
    border-radius: 4px;

    >div:nth-child(1) {
      width: 20%;
      height: 100%;
      background: #f8f8f8;
      display: flex;
      justify-content: center;
      align-items: center;
      color: gray;
      font-weight: 100;
      font-size: 13px;
    }

    >div:nth-child(2) {
      width: 80%;
      height: 100%;
      background: #f8f8f8;
      color: #65626c;

      >input {
        width: 100%;
        height: 100%;
        font-size: 13px;
        background: #f8f8f8;
        border: 1px solid transparent;

      }

      // > input::placeholder {
      //   padding-left: 0;
      //   margin-left: 0;
      //   font-weight: 400;
      //   color:#65626c
      // }
    }
  }
}

.filterSortOptions {
  width: 100%;
  margin: 8px 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 2%;

  .leftContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    >div:nth-child(1) {
      font-size: 13px;
      color: #65626c;
    }

    >div:nth-child(2) {
      border-radius: 4px;
      margin-left: 5px;

      >select {
        width: 100%;
        height: 40px;
        font-size: 13px;
        border: 1px solid transparent;
        background: #f8f8f8;
        color: #181423;
        font-weight: bolder;
      }
    }

    >div:nth-child(3) {
      // height: 60%;
      // width: 7%;
      // display: flex;
      // align-items: center;
      font-size: 13px;
      margin-left: 25px;
      color: #65626c;

    }

    >div:nth-child(4) {
      width: 201px;
      height: 40px;
      margin-left: 3%;
      display: flex;
      background: #f8f8f8;
      border-radius: 4px;

      >div:nth-child(1) {
        width: 20%;
        height: 100%;
        background: #f8f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
        font-weight: 100;
        font-size: 13px;
      }

      >div:nth-child(2) {
        width: 80%;
        height: 100%;
        background: #f8f8f8;
        color: #65626c;

        >input {
          width: 100%;
          height: 100%;
          font-size: 13px;
          background: #f8f8f8;
          border: 1px solid transparent;

        }
      }
    }
  }

  .rightContent {
    display: flex;
    width: auto;

    .filterByBtn {
      color: #3B86FF !important;
      border-color: #3B86FF !important;

      >img {
        width: 20px;
        height: 20px;
      }
    }

    .filterByBtn:hover {
      background: #E8F1FF 0% 0% no-repeat padding-box;
    }

    .sortBtn, .downloadExcel, .filterByBtn{
      margin-left: 15px;
    }
    .sortBtn {
      color: #3B86FF !important;
      border-color: #3B86FF !important;
      background: #E8F1FF 0% 0% no-repeat padding-box;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .downloadExcel {
    background-color: #E8F7EE !important;
    border-color: #cff1dd !important;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.healtharticle-table,
.webBookingsTable,
.adLandingFormsTable,
.frpStatesTable {
  font-size: 13px;
  overflow-x: scroll;
  border-radius: 8px;
  margin-left: 2%;
}

.career-main-table {
  overflow: scroll;
  height: 60vh;
}

/* Hide scrollbar for IE, Edge and Firefox */
.healtharticle-table,
.appDownloadLinkTable,
.InsidersTable,
.webBookingsTable,
.adLandingFormsTable,
.frpStatesTable {
  // margin-top: 3%;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  overflow: scroll;
  height: 60vh;

  table {
    width: 145%;
  }

  table thead tr {
    height: 30px;
  }

  table thead tr td {
    // td, th{
    border: 1px solid #d4d4d4;
    padding: 10px 10px;
    font-weight: 700;
    background: #f8f8f8;
    font-size: 13px;
    border-radius: 8px;
    position: sticky;
    top: 0;
  }

  th {
    font-weight: 700;
  }

  table tbody tr td {
    border: 1px solid #d4d4d4;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  table thead td:nth-child(1) {
    width: 1%;
    height: 60px;
    border-top-left-radius: 10px;
  }

  table thead td:nth-child(2) {
    width: 4%;
  }

  table thead td:nth-child(3) {
    width: 8%;
  }

  table thead td:nth-child(4) {
    width: 10%;
  }

  table thead td:nth-child(5) {
    width: 11%;
  }

  table thead td:nth-child(6) {
    width: 6%;
  }

  table thead td:nth-child(7) {
    width: 5%;
  }

  table thead td:nth-child(8) {
    width: 5%;
  }

  table thead td:nth-child(9) {
    width: 5%;
  }

  table tbody td:nth-child(9) {
    >img {
      width: 50% !important;
    }
  }

  table thead td:nth-child(10) {
    width: 6%;
  }
}

.appDownloadLinkTable {
  table thead td:nth-child(4) {
    width: 5%;
  }

  table thead td:nth-child(8) {
    width: 15%;
  }

}

.InsidersTable {
  table thead td:nth-child(2) {
    width: 10%;
  }

  table thead td:nth-child(3) {
    width: 10%;
  }
}

// table tbody tr td{
//     border: 1px solid #d4d4d4;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
// }
// table thead td:nth-child(1){
//     width: 3%;
//     height: 60px;
//     border-top-left-radius: 10px;
// }
// table thead td:nth-child(2){
//     width: 13%;
// }
// table thead td:nth-child(3){
//     width: 8%;
// }
// table thead td:nth-child(4){
//     width: 10%;
// }
// table thead td:nth-child(5){
//     width: 11%;
// }
// table thead td:nth-child(6){
//     width: 6%;
// }
// table thead td:nth-child(7){
//     width: 5%;
// }
// table thead td:nth-child(8){
//     width: 5%;
// }
// table thead td:nth-child(9){
//     width: 5%;
// }
// table tbody td:nth-child(8){
//     >img{
//         width: 50%;
//     }
// }
// table thead td:nth-child(10){
//     width: 20%;
// }

.inactive {
  color: #cece14;
  font-size: 13px;
  padding-left: 7%;
  cursor: pointer;
}

.inactive-health {
  color: #cece14;
  font-size: 13px;
  // padding-left: 7%;
  cursor: pointer;
}

.delete {
  color: #ff5349;
  font-size: 13px;
  padding-left: 7%;
}

.view {
  color: #b5dbbc;
  font-size: 13px;
  padding-left: 7%;
  cursor: pointer;
}

.edit {
  color: #0166c0;
  font-size: 13px;
  // padding-left: 7%;
  cursor: pointer;
}

.edit-health {
  color: #0166c0;
  font-size: 13px;
  padding-left: 7%;
  cursor: pointer;
}

table tbody tr td {
  padding: 20px 10px;
  font-size: 13px;
}

.inactive-icon {
  padding-left: 2%;
}

.edit-icon {
  padding-left: 2%;
}

.view-icon {
  padding-left: 2%;
  cursor: pointer;
}

.delete-icon {
  padding-left: 2%;
}

.webBookingsTable {
  table thead td:nth-child(2) {
    width: 7%;
  }

  table thead td:nth-child(5) {
    width: 15%;
  }

  table thead td:nth-child(10) {
    width: 8%;
  }

  table thead td:nth-child(11) {
    width: 10%;
  }

  table thead td:nth-child(13) {
    min-width: 120px;
  }
}

.adLandingFormsTable {
  .drop-down-arrow {
    color: black !important;
  }

  table tbody td:last-child {
    color: #CB1B5B !important;
  }

  table thead td:nth-child(1) {
    min-width: 100px;
  }

  table thead td:nth-child(2) {
    min-width: 200px;
  }

  table thead td:nth-child(3) {
    min-width: 200px;
  }

  table thead td:nth-child(4) {
    min-width: 200px;
  }

  table thead td:nth-child(5) {
    min-width: 200px;
  }

  table thead td:nth-child(6) {
    min-width: 200px;
  }

  table thead td:nth-child(7) {
    min-width: 200px;
  }

  table thead td:nth-child(8) {
    min-width: 200px;
  }

  table thead td:nth-child(9) {
    min-width: 200px;
  }

  table thead td:nth-child(10) {
    min-width: 150px;
  }

  table thead td:nth-child(11) {
    min-width: 150px;
  }

  table thead td:nth-child(12) {
    min-width: 150px;
  }

  table thead td:nth-child(13) {
    min-width: 150px;
  }

  table thead td:nth-child(14) {
    min-width: 150px;
  }

  table thead td:nth-child(15) {
    min-width: 150px;
  }

  table thead td:nth-child(16) {
    min-width: 150px;
  }

  table thead td:nth-child(17) {
    min-width: 200px;
  }

  table thead td:nth-child(18) {
    min-width: 300px;
  }

  table thead td:nth-child(19) {
    min-width: 150px;
  }

  table thead td:nth-child(20) {
    min-width: 150px;
  }

  table thead td:nth-child(21) {
    min-width: 150px;
  }

  table thead td:nth-child(22) {
    min-width: 200px;
  }

  table thead td:nth-child(23) {
    min-width: 200px;
  }

  table thead td:nth-child(24) {
    min-width: 300px;
  }

}

.frpStatesTable {
  table thead td:nth-child(2) {
    width: 6%;
  }

  table thead td:nth-child(3) {
    width: 5%;
  }
}

.clinicProfileTable {
  table tbody td:nth-child(9) {
    >img {
      width: 100px !important;
    }
  }
}