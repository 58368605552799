  #exampleModal2 .modal-dialog {
     margin-top: 13rem;
  }

  #exampleModal2 .modal-content {
     padding-left: 1rem;
     padding-right: 1rem;
     height: 311px;
     width: 356px;
     border-radius: 15px;

     >div {
        width: 148px;
        height: 148px;
        margin: auto;
     }

     >p {
        font-weight: 600;
        color: #181423;
     }
  }

  #removeModal .removeModal-dialog {
     height: 100% !important;
     align-items: center;
     display: flex;
     justify-content: center;
  }


  #removeModal .removeModal-content {
     padding-left: 1rem;
     padding-right: 1rem;
     width: 20%;
     border-radius: 15px;

     >p {
        font-weight: 600;
        color: #181423;
     }
  }

  .removeModal-content {
     position: relative;
     display: flex;
     flex-direction: column;
     width: 100%;
     color: var(--bs-modal-color);
     pointer-events: auto;
     background-color: var(--bs-modal-bg);
     background-clip: padding-box;
     border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
     border-radius: var(--bs-modal-border-radius);
     outline: 0;
  }

  #deleteModal .modal-dialog {
     margin-top: 20%;
     margin-left: 35% !important;
  }

  #deleteModal .modal-content {
     padding-left: 1rem;
     padding-right: 1rem;
     border-radius: 15px;

     >div {
        margin: auto;
     }

     >p {
        font-weight: 600;
        color: #181423;
     }
  }

  .assignModal {
     #exampleModal2 .modal-content {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 275px;
        width: 450px;
        border-radius: 15px;
     }

     .assignSubmit {
        padding: 12px;
        font-size: 20px;
        background: #c1c1c1;
        color: white;
        border: none;
        border-radius: 5px;
        margin-top: 30px;
        width: 225px;
     }

     .assignName {
        font-size: 13px;
        font-weight: 400 !important;
     }

     .assignTitle {
        font-size: 22px;
     }
  }

  #confirmModal .modal-dialog {
     margin-top: 13rem;

     .modal-content {
        min-width: 400px;
     }

     @media screen and (max-width: 600px) {
        .modal-content {
           min-width: 280px !important;
           max-width: 380px !important;
        }
     }
  }

  .confirmBtn {
     color: gray !important;
     border: 1px solid gray !important;
     width: 90px;
     height: 40px;

  }

  .confirmBtn:hover {
     color: #1a6ae4 !important;
     border: 1px solid #1a6ae4 !important;
     background: #d7e6fd !important;
  }