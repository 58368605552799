.home-healthcare-service-provider{
    table{
        width: 250%;
    }
    table thead tr td:nth-child(1){
        width: 60px;
    }
    table thead tr td:nth-child(2){
        width: 120px;
    }
    table thead tr td:nth-child(3){
        width: 120px;
    }
    table thead tr td:nth-child(4){
        width: 120px;
    }
    table thead tr td:nth-child(5){
        width: 120px;
    }
    table thead tr td:nth-child(6){
        width: 120px;
    }
    table thead tr td:nth-child(7){
        width: 120px;
    }
    table thead tr td:nth-child(8){
        width: 120px;
    }
    table thead tr td:nth-child(9){
        width: 120px;
    }
    table thead tr td:nth-child(10){
        width: 180px;
    }
    table thead tr td:nth-child(11){
        width: 120px;
    }
    table thead tr td:nth-child(12){
        width: 120px;
    }
    table thead tr td:nth-child(13){
        width: 120px;
    }
    table thead tr td:nth-child(14){
        width: 220px;
    }
    table thead tr td:nth-child(15){
        width: 120px;
    }
    table thead tr td:nth-child(16){
        width: 180px;
    }
    table thead tr td:nth-child(17){
        width: 240px;
    }
    table thead tr td:nth-child(18){
        width: 150px;
    }
    table thead tr td:nth-child(19){
        width: 150px;
    }
    table thead tr td:nth-child(20){
        width: 200px;
    }
    table thead tr td:nth-child(21){
        width: 400px;
    }
}

.healthcare-activate{
    color: red;
    padding-left: 5%;
    cursor: pointer;
}