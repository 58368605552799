.reportAccept {
    background: #fff;
    color: green;
    border: none;
}

.reportReject {
    color: red;
}

.doctorReport {
    width: 10%;
}