.file-upload-label {
  overflow: scroll;
  font-size: 12px;
  width: 70%;
  margin-top: 5%;
}

.file-upload-label::-webkit-scrollbar {
  display: none;
}

#img-preview1,
#img-preview2,
#img-preview3,
#img-preview4,
#img-preview5,
#img-preview6,
#img-preview7,
#img-preview8,
#img-preview9,
#img-preview10,
#img-preview11,
#img-preview12,
#img-preview13,
#img-preview14,
#img-preview15,
#img-preview16,
#img-preview17,
#img-preview18 {
  display: none;
  width: 80%;
  // border: 2px dashed #333;
  margin-bottom: 20px;
}
#img-preview1 img,
#img-preview2 img,
#img-preview3 img,
#img-preview4 img,
#img-preview5 img,
#img-preview6 img,
#img-preview7 img,
#img-preview8 img,
#img-preview9 img,
#img-preview10 img,
#img-preview11 img,
#img-preview12 img,
#img-preview13 img,
#img-preview14 img,
#img-preview15 img,
#img-preview16 img,
#img-preview17 img,
#img-preview18 img {
  width: 100%;
  height: auto;
  display: block;
}
