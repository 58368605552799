.clinicProfile {
    .doctorSpecialisation {
        width: 100%;
        border: 1px solid rgb(231, 230, 230);
        border-radius: 10px;
    }

    .doctorsTiming {
        width: 100px;
        height: 50px;
        border: 1px solid #ddd9d9;
        border-radius: 5px;
    }

    .doctorAddTime {
        width: 100px;
        border: 1px solid #ddd9d9;
        border-radius: 5px;
    }

    .doctorEndTime {
        padding-left: 320px;
    }

    .registrationEndTime {
        padding-left: 175px;
    }

    .doctorAddNewBack {
        width: 29%;
        height: 104%;
    }
}

.doctorsFaq {

    .doctorAddNewInput {
        background: #f8f8f8;
    }
}

.addNewAnswerWrapper {
    border-radius: 5px;
    // height: 0px !important;  
    background: #fff;
    border: 1px solid #dcd8d8;
}

.clinicProfilePhotos {
    overflow: scroll;
    .multiImageUploader {
        width: 140px;
        height: 40px;
        padding-top: 15px;
    }


}

.doctorClinicProfile {
    width: 8%;
}