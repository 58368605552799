.position-desgn{
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B;
}

.career-position-table{
    width: 97%;
    margin: auto;
    // margin-top: 2%;
    border-radius: 8px;
    >table{
        width: 100%;
        
        >thead tr td:nth-child(1){
            width: 7%;
            border-top-left-radius: 8px;
            padding: 10px 10px;
            padding-bottom: 1.5rem;
        }
        >thead tr td:nth-child(2){
            width: 17%;
        }
        >thead tr td:nth-child(3){
            width: 20%;
            padding-bottom: 1.8rem;
            padding-right: 1rem;
        }
        >thead tr td:nth-child(4){
            width: 53%;
            padding: 10px 10px;
            border-top-right-radius: 8px;
            padding-bottom: 1.8rem;
        }
        >thead tr td{
            font-weight: bold;
            font-size: 13px;
            background: #f7f6f6;
            border: 1px solid #D4D4D4;
        }
        >tbody tr td{
            font-size: 13px;
            padding-left: 2%;
            padding-top: 2%;
            border: 1px solid #D4D4D4;
        }
    }
}