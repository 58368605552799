.job-post {
    border-bottom: 3px solid #CB1B5B;
    color: #CB1B5B !important;
}

// .careers-tab {
    // margin-bottom: 3%;
// }

.sortByPositionNew {
    width: 120px;
    height: 45px;
    padding: 10px 10px;
    border-radius: 8px;
    margin-bottom: 3%;
    display: flex;
    background: #e8f1ff;
    z-index: 1;
    position: relative;

    >div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

    }
}

.SortByposition {
    width: 120px;
    height: 6vh;
    padding: 10px 10px;
    border-radius: 8px;
    margin-bottom: 3%;
    display: flex;
    background: #e8f1ff;

    >div:nth-child(1) {
        width: 70%;
        height: 100%;
        background: #e8f1ff;

        >select {
            width: 100%;
            appearance: none;
            color: #82b2ff;
            background: #e8f1ff;
            text-align: center;
        }
    }

    >div:nth-child(2) {
        width: 30%;
    }
}

.SortByDepartment {
    width: 120px;
    height: 6vh;
    padding: 10px 10px;
    border-radius: 8px;
    display: flex;
    background: #fff;
    border: 1px solid #82b2ff;
    margin-left: 5%;
    margin-bottom: 3%;

    >div:nth-child(1) {
        width: 70%;
        height: 100%;
        background: #fff;

        >select {
            width: 100%;
            appearance: none;
            color: #82b2ff;
            background: #fff;
            text-align: center;
        }
    }

    >div:nth-child(2) {
        width: 30%;
    }
}


.career-jobpost-table {
    // margin-top: 2%;
    border-radius: 8px;

    >table {
        // width: 200%;
        width: 1716px;

        >thead tr td:nth-child(1) {
            // width: 6%;
            width: 60px;
            border-top-left-radius: 8px;
            padding: 10px 10px;
        }

        >thead tr td:nth-child(2) {
            // width: 10%;
            width: 150px;
            padding: 10px 10px;
        }

        >thead tr td:nth-child(3) {
            // width: 15%;
            width: 120px;
            padding: 10px 10px;
        }

        >thead tr td:nth-child(4) {
            // width: 10%;
            width: 120px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(5) {
            // width: 5%;
            width: 150px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(6) {
            // width: 10%;
            width: 150px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(7) {
            // width: 5%;
            // width: 250px;
            padding: 10px 10px;
            border-top-right-radius: 8px;

        }

        >thead tr td:nth-child(8) {
            // width: 5%;
            width: 150px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(9) {
            // width: 5%;
            width: 120px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(10) {
            // width: 5%;
            width: 120px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(11) {
            // width: 5%;
            width: 120px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td:nth-child(12) {
            // width: 20%;
            width: 250px;
            padding: 10px 10px;
            border-top-right-radius: 8px;
        }

        >thead tr td {
            font-weight: bold;
            font-size: 15px;
            background: #f7f6f6;
            // border: 1px solid #D4D4D4;
        }

        >tbody tr td {
            font-size: 13px;
            border: 1px solid #D4D4D4;
        }

        >tbody tr td:nth-child(7) {
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 10em;
            line-height: 1em;
            border-radius: 0px;
            object-fit: fill;
            overflow: scroll;
            width: 200px !important;
        }

        >tbody tr td:nth-child(7)::-webkit-scrollbar {
            display: none;
        }
    }

    table tbody tr td {
        padding-left: 1%;
    }

    table thead tr td {
        text-align: left;
        padding: 10px 5px !important;
        padding-left: 1% !important;
    }

    // table tbody tr td:nth-child(7) {
        // text-indent: -9999px;
        // white-space: nowrap;
    // }
}

.sortandfilter {
    margin-right: 3%;
}

#addnewpost {
    padding: 10px 10px;

    >div:nth-child(1) {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    // >div:nth-child(2) {}
}


//Sort By portion
.sortby-Common-career-2 {
    width: 142px;
    height: 178px;
    position: absolute;
    top: 49px;
    // right: 290px;
    // top: 390px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;

    >div {
        height: 100%;
    }

    >div div {
        width: 80%;
        margin: auto;
        height: 25%;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 13px;
        color: #65626C;
        cursor: pointer;
    }
}

.sortby-jobpost {
    right: 18% !important;
}

.sortby-Common-career {
    width: 142px;
    height: 178px;
    position: absolute;
    right: 12%;
    top: 65%;
    // right: 290px;
    // top: 390px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;

    >div {
        width: 80%;
        margin: auto;
        height: 25%;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 13px;
        color: #65626C;
        cursor: pointer;
    }
}

//filter portion
.filter-container-career {
    width: 229px;
    height: 385px;
    position: fixed;
    left: 40%;
    top: 35%;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 2s;
}

// Doctor Filter Fee
.filter-doctor-fee-career {
    width: 229px;
    position: fixed;
    left: 58%;
    top: 35%;
    box-shadow: 0px 1px 10px #47474729;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 0.2s;
    animation: animationCard 0.2s linear;
}

.jobPostDescription {
    font-family: "Mulish" !important;
    font-size: 13px;
    border-top: 1px !important;
    border-left: 1px !important;
    border-right: 1px !important;
    min-height: 10em;

    p,
    strong,
    span {
        font-family: "Mulish" !important;
        font-size: 13px !important;
        white-space: break-spaces !important;
        line-height: 24px !important;
    }
}