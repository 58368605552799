.health-active{
    width: 100%;
    height: 10vh;
    margin-top: 3%;
    display: flex;
}

.active-box{
    height: 50px;
    border: 1px solid #D4D4D4;
    display: flex;
    justify-content: left;
    padding-left: 5%;
    align-items: center;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
}

.active-title{
    font-weight: 700;
    font-size: 13px;
}

.common-active{

}