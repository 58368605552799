.car-pos-form{
    margin-top: 3%;
}
.car-pos-form-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25%;
    >button{
        width: 166px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #54a464;
        background: #54a464;
        color: white;
    }
}

.carr-pos-form{
    width: 97%;
    margin: auto;
}
label{
    color: #4B4853 !important;
}