// input::placeholder{
//     font-weight:bold;
//     font-size: 0.8rem;
// }

.job-location input{
    width: 30%;
}
 label span{
    color:#CB1B5B
 }

.upload-img input{
  width: 120px;
}
.upload-img input,textarea{
    background-color: rgb(93, 91, 91);
}
textarea::placeholder{
    font-weight:400;
    color:black;

}
input[type="date"] { 
    background: transparent;
    color: grey;
    height: 50px;
  }

//   input[type="text"] { 
//     background: transparent;
//     color: grey;
//     height: 50px;
//   }
//   input[type="text"]::placeholder { 
//     font-weight: 400;
//     color: grey;
//     font-size: 14px;
//   }

  input[type="number"] { 
    background: transparent;
    color: grey;
    height: 50px;
  }

  

.car-text{
    font-size: 13px;
    font: normal normal medium 13px/40px Heebo;
    letter-spacing: 0px;
    opacity: 1;
}



.form-select{
    height: 50px;
}
// .form-control{
//     height: 50px;
// }
.custom-file-input{
    background: #cccccc;
    border-radius: 8px;
}
.form-control-file{
    background: #cccccc;
    border-radius: 8px;
    height: 40px;
}
.career-jobpost-form, .addEditInsiders{
    width: 96%;
    margin: auto;
    margin-top: 3%;
}
#car-job-des{
    height: 100px;
    font: normal normal medium 13px/40px Heebo;
    letter-spacing: 0px;
    opacity: 1;
}
::-webkit-calendar-picker-indicator {
    // filter: invert(1);
    color: red;
}
.car-job-button{
    margin-top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    >button{
        width: 166px;
        height: 50px;
        background: #54a464;
        color: white;
        border: 1px solid #54a464;
        border-radius: 4px;
        margin-bottom: 5%;
    }
}

    label{
        color:#4B4853 !important
    }

// .car-job-select{
//     height: 50px;
// }
// .car-job-input{
//     height: 50px;
// }


#img-preview {
    display: none;
    width: 80%;
    // border: 2px dashed #333;  
    margin-bottom: 20px;
  }
  #img-preview img{
    width: 100%;
    height: auto;
    display: block;
  }