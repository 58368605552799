.sortby-master-health {
    top: 50%;
}


.page-item:first-child,
.page-item:first-child,
.page-link {
    border-radius: 0 !important;
}

.success {
    color: green;
}

.failed {
    color: #ffc34d;
}